import { CloseOutlined } from '@ant-design/icons'
import Drawer from 'react-modern-drawer'
import { Button, Image } from 'antd'
import React, { useState } from 'react'
import qryape from '../../img/qryape.png';
import Tarjetas from '../../img/tarjetas.png';
import ModalSimulacion from './ModalSimulacion';

function DrawerYape({ openYape, setOpenYape, pFinal, listFinal }) {

    const pedido = JSON.parse(sessionStorage.getItem('pedido'));
    const [mostrasModal, setMostrasModal] = useState(false);


    console.log(pedido, 'llevar');
    const toggleDrawer = () => {
        setOpenYape(false)
    }

    const mostrarModal =()=>{
        console.log('mostral modal');
        setMostrasModal(true);
    }

    return (
        <Drawer
            open={openYape}
            onClose={toggleDrawer}
            direction='bottom'
            // className='bla bla bla'
            style={{ backgroundColor: '#700375', width: '80%', height: '85%', marginLeft: '10%', borderRadius: '50px 50px 0 0' }}
        >
            <div style={{ width: '100%', padding: '30px', textAlign: 'end' }}>
                <Button onClick={() => setOpenYape(false)} style={{ borderRadius: '20px' }} icon={<CloseOutlined style={{ fontSize: '30px' }} />} ></Button>
            </div>
            <h1 style={{ fontSize: '40px', textAlign: 'center', color: 'white' }}>Total a pagar</h1>
            <h1 style={{ fontSize: '80px', textAlign: 'center', fontWeight: '600', color: '#FAE200' }}>{'S/' + pFinal}</h1>
            {/* <h1 style={{ fontSize: '80px', textAlign: 'center', fontWeight: '600', color: '#FAE200' }}></h1> */}

            <div style={{ textAlign: 'center' }}>
                <img style={{ width: '650px' }} src={qryape} />.
                <h1 style={{ color: 'white', fontSize: '40px' }}>SUPERMERCADOS MIA SAC</h1>
                <h1 style={{ color: 'white', fontSize: '30px' }}> ESCANE NUESTRO QR YAPE Y PAGA EN MONTO INDICADO ARRIBA</h1>

                <div style={{ fontSize: '30px', textAlign: 'center' }}><a onClick={() => setOpenYape(false)} style={{ color: 'white' }}>Volver</a></div>
            </div>
            <div style={{ textAlign: 'center' }}>
                <Button style={{ zIndex: '50', width: '250px', height: "60px", fontSize: '30px' }} onClick={() => mostrarModal()}>Simular Pago</Button>
            </div>
            <div style={{ position: 'absolute', bottom: '5px' }}>
                <h1 style={{ paddingTop: '100px', textAlign: 'center', fontSize: '35px', color: 'white' }}>Aceptamos  todas las tarjetas  de debito y credito.</h1>
                <img style={{ width: '100%', height: '100px' }} src={Tarjetas} />
            </div>
            {
                mostrasModal ?
                    <ModalSimulacion mostrasModal={mostrasModal} setMostrasModal={setMostrasModal} setOpenIns={setOpenYape} listFinal={listFinal} pFinal={pFinal} />
                    : null
            }
        </Drawer>
    )
}

export default DrawerYape