import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "antd";
import { useHistory } from "react-router-dom";
import Gif from "../../img/procesando.gif";
import imgCheck from "../../img/check.png";
import { fnNewOrder } from "../../data/sales";
import qz from "qz-tray";
import { API } from "../../util/api";
import { clearOrderData } from "../../util/functions";

function ModalSendOrderToCheckout({ listFinal, pFinal }) {
  const [show, setShow] = useState(true);
  const [message, setMessage] = useState("Enviando pedido…");
  const [message2, setMessage2] = useState("");
  const [imageModal, setImageModal] = useState(Gif);
  const history = useHistory();
  const timeoutRef = useRef(null);

  const tipoDoc = sessionStorage.getItem("tipoComprobante");
  const cliente = JSON.parse(sessionStorage.getItem("datosClientePedido"));
  const establecimiento = JSON.parse(localStorage.getItem("token_activo"))
    .data[0].idEstableciomiento;

  const tipoPedido = localStorage.getItem("tipoPedido");

  const sendOrder = async () => {
    const params = {
      montoCentimos: parseInt(
        parseFloat(parseFloat(pFinal).toFixed(2)) * 100,
        10
      ),
      montoTotal: parseFloat(pFinal).toFixed(2),
      idEstableciomiento: establecimiento,
      listaFinal: listFinal,
      tipoDoc: tipoDoc,
      cliente: cliente,
      tipoPedido:
        tipoPedido === "toGo" ? "Para llevar" : "Para consumir en el local",
    };

    console.log(`params`, params);
    const response = await fnNewOrder(params);
    console.log(`response`, response);

    if (!response.data.success)
      throw new Error("No se pudo obtener el número de orden");

    const purchaseOrderNumber = response.data.data;
    console.log(`purchaseOrderNumber`, purchaseOrderNumber);
    sessionStorage.setItem("purchaseOrderNumber", purchaseOrderNumber);
    sessionStorage.setItem("orderId", JSON.stringify(purchaseOrderNumber));
    // borrar el localstorage del pedido
    clearOrderData();
    return purchaseOrderNumber;
  };

  const printOrder = async (
    printer,
    dataPrint,
    objByConfig = null,
    _callback = null,
    cortar = false
  ) => {
    try {
      // Verificar si el navegador admite QZ Tray
      // if (!qz.websocket.isActive()) {
      //   throw new Error("QZ Tray no está instalado o no está en funcionamiento.");
      // }

      qz.security.setCertificatePromise(function (resolve, reject) {
        fetch(`${API}/files/cert`, {
          cache: "no-store",
          headers: { "Content-Type": "text/plain" },
        }).then(function (data) {
          data.ok ? resolve(data.text()) : reject(data.text());
        });
      });

      qz.security.setSignaturePromise(function (toSign) {
        return function (resolve, reject) {
          fetch(`${API}/qz-tray/sign-message?request=${toSign}`, {
            cache: "no-store",
            headers: { "Content-Type": "text/plain" },
          }).then(function (data) {
            data.ok ? resolve(data.text()) : reject(data.text());
          });
        };
      });

      let config = null;

      console.log(`printer`, printer);
      console.log(`dataPrint`, dataPrint);
      // Verificar si la impresora es válida
      // const printerList = await qz.printers.find();
      // if (!printerList.includes(printer)) {
      //     throw new Error("La impresora no existe o no está disponible.");
      // }

      qz.websocket
        .connect()
        .then(() => {
          console.log(`connect ${printer}`);

          if (objByConfig === null) {
            console.log("NOT objByConfig");
            config = qz.configs.create(printer);
          } else {
            console.log("objByConfig");
            config = qz.configs.create(printer, objByConfig);
          }

          console.log(dataPrint);
          qz.print(config, dataPrint).then(async () => {
            if (cortar) {
              const configCut = qz.configs.create(printer, {
                encoding: "ISO-8859-1",
                altPrinting: true,
              });

              const dataCut = ["\u001ba\u0001", "\n", "\n", "\u001dVA2"];

              qz.print(configCut, dataCut)
                .then(async () => {
                  qz.websocket.disconnect();
                  if (_callback !== null) {
                    _callback();
                  }
                })
                .catch((error) => {
                  throw new Error(error);
                });
            } else {
              qz.websocket.disconnect();
            }
          });
        })
        .catch((error) => {
          throw new Error(error);
        });
    } catch (error) {
      // throw new Error(error);
      // Capturar el error y mostrar una alerta con el mensaje
      console.error(error);
      alert("Error: " + error.message);
    }
  };

  const finish = () => {
    setMessage("Acércate a cancelar a caja.");
    setMessage2("GRACIAS POR TU PEDIDO");
    setImageModal(imgCheck);

    timeoutRef.current = setTimeout(() => {
      setShow(false);
      // redireccionar al main para nuevo pedido
      history.push("/main");
    }, 10000);
  };

  const goOut = () => {
    setShow(false);
    clearTimeout(timeoutRef.current); // Limpia el temporizador
    // redireccionar al main para nuevo pedido
    history.push("/main");
  };

  useEffect(async () => {
    const orderId = await sendOrder();
    if (orderId > 0) {
      setMessage("Imprimiendo pedido...");
      const data = [
        {
          type: "pixel",
          format: "pdf",
          flavor: "file",
          data: `${API}/orders/print?order_id=${orderId}&establecimiento_id=${establecimiento}`,
        },
      ];
      let printerKiosk = `POS80-KIOSCO`;
      // finish();
      printOrder(printerKiosk, data, null, finish, true);
    }
  }, []);

  return (
    <Modal
      visible={show}
      onCancel={goOut}
      footer={[]}
      width={900}
      centered
      modalRender={(modal) => (
        <div style={{ borderRadius: "20px", overflow: "hidden" }}>{modal}</div>
      )}
    >
      <div style={{ margin: "auto auto", height: "100%" }}>
        <div style={{ marginTop: "2rem", textAlign: "center" }}>
          <img src={imageModal} style={{ maxHeight: "350px" }} alt="cargando" />
          <h2 style={{ marginTop: "2rem", fontSize: "3.5rem" }}>{message}</h2>
          {message2 !== "" && (
            <h2 style={{ marginTop: "2rem", fontSize: "3.5rem" }}>
              {message2}
            </h2>
          )}
          {message2 !== "" && (
            <Button
              onClick={goOut}
              style={{
                backgroundColor: "#A50B70",
                marginTop: "20px",
                fontSize: "3.5rem",
                color: "#fff",
                borderRadius: "15px",
                padding: "2rem 3rem",
                height: "auto",
              }}
            >
              SALIR
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ModalSendOrderToCheckout;
