import { CloseOutlined } from "@ant-design/icons";
import Drawer from "react-modern-drawer";
import { Button } from "antd";
import React, { useState } from "react";
import DrawerVoucher from "./DrawerVoucher";

function DrawerComprobante({
  listFinal,
  mostrarComprobante,
  setMostrarComprobante,
  pFinal,
  setPagar,
}) {
  const [mostrarBoleta, setMostrarBoleta] = useState(false);

  const RECEIPT = "BOLETA ELECTRÓNICA";
  const INVOICE = "FACTURA ELECTRÓNICA";

  // DrawerVoucher
  const [openSalesReceipt, setOpenSalesReceipt] = useState(false);
  const selectSalesReceipt = (salesReceipt) => {
    localStorage.setItem("salesReceipt", salesReceipt);
    if (salesReceipt === "FACTURA ELECTRÓNICA") {
      sessionStorage.setItem("tipoComprobante", 1);
      localStorage.setItem("tipoComprobante", 1);
    } else {
      sessionStorage.setItem("tipoComprobante", 3);
      localStorage.setItem("tipoComprobante", 3);
    }
    setOpenSalesReceipt(true);
  };

  const toggleDrawer = () => {
    setMostrarComprobante(false);
  };

  return (
    <div>
      <Drawer
        open={mostrarComprobante}
        onClose={toggleDrawer}
        direction="bottom"
        style={{
          width: "80%",
          height: "60%",
          marginLeft: "10%",
          borderRadius: "50px 50px 0 0",
        }}
      >
        <div style={{ width: "100%", padding: "30px", textAlign: "end" }}>
          <Button
            onClick={() => setMostrarComprobante(false)}
            style={{ borderRadius: "20px" }}
            icon={<CloseOutlined style={{ fontSize: "30px" }} />}
          ></Button>
        </div>
        <h1 style={{ fontSize: "40px", textAlign: "center" }}>
          Selecciona Comprobante
        </h1>

        <h1
          style={{
            fontSize: "30px",
            textAlign: "center",
            fontWeight: "400",
            paddingTop: "50px",
            color: "#A8A8A8",
          }}
        >
          {" "}
          Elige el comprobante que desees recibir
        </h1>
        <div style={{ textAlign: "center", paddingTop: "50px" }}>
          <div>
            <Button
              onClick={() => selectSalesReceipt(RECEIPT)}
              style={{
                fontSize: "35px",
                color: "#A50B70",
                height: "100px",
                fontWeight: "400",
                width: "50%",
                borderRadius: "15px",
              }}
            >
              BOLETA
            </Button>
          </div>
          <div>
            <Button
              onClick={() => selectSalesReceipt(INVOICE)}
              style={{
                fontSize: "35px",
                color: "#A50B70",
                height: "100px",
                fontWeight: "400",
                width: "50%",
                borderRadius: "15px",
              }}
            >
              FACTURA
            </Button>
          </div>
        </div>
      </Drawer>

      {openSalesReceipt ? (
        <DrawerVoucher
          open={openSalesReceipt}
          setOpen={setOpenSalesReceipt}
          setPagar={setPagar}
          setMostrarComprobante={setMostrarComprobante}
          listFinal={listFinal}
          pFinal={pFinal}
        />
      ) : null}
    </div>
  );
}

export default DrawerComprobante;
