import React from "react";
import "./style.css";
import { getUrlImage } from "../../../util/functions";

export default function CardCategorias({ mostrarSubCatergoria, data, user }) {
  console.log(user.data[0].colorPrimario, "user");
  return (
    <div
      onClick={() => mostrarSubCatergoria(data.idCategoria, data.nombreCategoria)}
      id="id_card_categoria"
    >
      <img src={getUrlImage(data.urlImagen)} alt="Categorias de productos" />
      <div className="cls_title">
        {/* <h2 style={{ color: `${user.data[0].colorSecundario}` }} >{data.nombreCategoria}</h2> */}
        <h2>{data.nombreCategoria}</h2>
      </div>
    </div>
  );
}
