/* eslint-disable no-undef */
import { CloseOutlined, CreditCardOutlined, } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import Drawer from "react-modern-drawer";
import { API_KEY, CULQI_CHECKOUT } from "../../constants/constantes";
import { httpClient } from "../../util/api";
// import bolsaPlastico from "../../img/bolsaPlastico.png";
// import bolsaPapel from "../../img/bolsaPapel.png";
import Tarjetas from "../../img/tarjetas.png";
import yape from "../../img/yape.png";
import plin from "../../img/plin.png";
import DrawerPlin from "./DrawerPlin";
import DrawerYape from "./DrawerYape";
import DrawerInstrucciones from "./DrawerTarjeta";
import DrawerPrint from "./DrawerPrint";

Culqi.publicKey = API_KEY.PUBLIC;

export function culqi() {
  if (Culqi.token) {
    //* ¡Objeto Token creado exitosamente!
    const token = Culqi.token.id;
    console.log("Se ha creado un Token: ", token);
    const datosClientePedido = JSON.parse(
      sessionStorage.getItem("datosClientePedido")
    );
    const orderId = JSON.parse(sessionStorage.getItem("orderId"));
    const body = {
      token,
      idVenta: orderId,
      email: datosClientePedido.email,
    };
    httpClient.post(`/culqi/aprobar`, body).then((resp) => {
      if (!resp.data.success) throw new Error("No se pudo aprobar el pago");
      console.log(resp);
      if (
        resp.data.data?.outcome?.user_message === "Su compra ha sido exitosa."
      ) {
        Culqi.close();
        // TODO: continuar con el flujo de la compra
      }
    });
  } else if (Culqi.order) {
    //* ¡Objeto Order creado exitosamente!
    const order = Culqi.order;
    console.log("Se ha creado el objeto Order: ", order);
  } else {
    //* Mostramos JSON de objeto error en consola
    console.log("Error : ", Culqi.error);
  }
}

function DrawerParaLlevar(props) {
  const { pagar, setPagar, cantidad, pFinal, listFinal } = props;
  const tipoPedido = localStorage.getItem("tipoPedido");
  const datosClientePedido = JSON.parse(
    sessionStorage.getItem("datosClientePedido")
  );

  const [contBolsaPapel, setContBolsaPapel] = useState(0);
  const [contBolsaPlastico, setContBolsaPlastico] = useState(0);
  const [openPlin, setOpenPlin] = useState(false);
  const [openYape, setOpenYape] = useState(false);
  const [openIns, setOpenIns] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [mostrarFormasPago, setMostrarFormasPago] = useState(true);

  const obtenerNumeroOrden = async () => {
    const tipoDoc = sessionStorage.getItem("tipoComprobante");
    const cliente = JSON.parse(sessionStorage.getItem("datosClientePedido"));
    const establecimiento = JSON.parse(localStorage.getItem("token_activo"))
      .data[0].idEstableciomiento;
    const resp = await httpClient.post(`/culqi/nuevoPedido`, {
      montoCentimos: parseInt(
        parseFloat(parseFloat(pFinal).toFixed(2)) * 100,
        10
      ),
      montoTotal: parseFloat(pFinal).toFixed(2),
      idEstableciomiento: establecimiento,
      listaFinal: listFinal,
      tipoDoc: tipoDoc,
      cliente: cliente,
    });
    console.log(resp);
    if (!resp.data.success)
      throw new Error("No se pudo obtener el número de orden");
    sessionStorage.setItem("orderId", JSON.stringify(resp.data.data));
    return resp.data.data;
  };

  const clickBtnCulqi = async () => {
    const order_number = await obtenerNumeroOrden();
    if (order_number > 0) {
      alert("Se registro la venta con éxito.");
    }
  }

  const showCulqi = async () => {
    const order_number = await obtenerNumeroOrden();
    const expiration_date = Math.floor(new Date().getTime() / 1000) + 86400;
    const first_name = datosClientePedido.nombreCompleto.split(" ")[2];
    const last_name = [
      datosClientePedido.nombreCompleto.split(" ")[0],
      datosClientePedido.nombreCompleto.split(" ")[1],
    ].join(" ");
    const client = {
      first_name,
      last_name,
      email: datosClientePedido.email,
      phone_number: datosClientePedido.celular,
    };
    const product = {
      description: `Compra en Kiosko por ${pFinal} soles`,
      currency_code: "PEN",
      amount: parseInt(parseFloat(parseFloat(pFinal).toFixed(2)) * 100, 10),
    };
    const body = JSON.stringify({
      ...product,
      order_number,
      expiration_date,
      client_details: {
        ...client,
      },
      confirm: false,
    });

    fetch("https://api.culqi.com/v2/orders", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + API_KEY.SECRET,
      },
      body,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.object === "error") throw new Error(data.user_message);
        Culqi.settings({
          title: CULQI_CHECKOUT.TITLE_STORE,
          currency: "PEN",
          amount: parseInt(parseFloat(parseFloat(pFinal).toFixed(2)) * 100, 10),
          order: data.id,
        });
        Culqi.options({
          lang: "auto",
          installments: false,
          paymentMethods: {
            tarjeta: false,
            yape: false,
            bancaMovil: false,
            agente: false,
            billetera: true,
            cuotealo: false,
          },
        });
        Culqi.open();
      })
      .catch((err) => console.error(err));
  };

  const pedido = JSON.parse(sessionStorage.getItem("pedido"));
  console.log(pedido, "llevar");
  const toggleDrawer = () => {
    setPagar(false);
  };

  const volverT = () => {
    if (tipoPedido === "toGo") {
      setMostrarFormasPago(true);
    } else {
      setPagar(false);
    }
  };

  const sumarPapel = () => {
    const a = contBolsaPapel + 1;
    setContBolsaPapel(a);
  };

  const restarPapel = () => {
    const a = contBolsaPapel - 1;
    setContBolsaPapel(a);
  };
  const sumarPlastico = () => {
    const a = contBolsaPlastico + 1;
    setContBolsaPlastico(a);
  };

  const restarPlastico = () => {
    const a = contBolsaPlastico - 1;
    setContBolsaPlastico(a);
  };

  return (
    <div>
      <Drawer
        open={pagar}
        onClose={toggleDrawer}
        direction="bottom"
        style={{
          width: "80%",
          height: "60%",
          marginLeft: "10%",
          borderRadius: "50px 50px 0 0",
          backgroundColor: "#F2F2F2",
        }}
      >
        <div style={{ width: "100%", padding: "30px", textAlign: "end" }}>
          <Button
            onClick={() => setPagar(false)}
            style={{ borderRadius: "20px" }}
            icon={<CloseOutlined style={{ fontSize: "30px" }} />}
          ></Button>
        </div>
        <h1 style={{ fontSize: "40px", textAlign: "center" }}>Total a pagar</h1>
        <h1
          style={{
            fontSize: "80px",
            textAlign: "center",
            fontWeight: "600",
            color: "#A50B70",
          }}
        >
          {"S/" + pFinal}
        </h1>

        {mostrarFormasPago ? (
          <div style={{ padding: "30px 0 0 0 " }}>
            <h1 style={{ textAlign: "center" }}>
              Seleccionar una forma de pago
            </h1>

            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setOpenIns(true)}
                  style={{
                    fontSize: "35px",
                    height: "70px",
                    width: "55%",
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor: "#A50B70",
                  }}
                  icon={<CreditCardOutlined style={{ paddingRight: "15px" }} />}
                >
                  Tarjeta de Credito
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setOpenYape(true)}
                  style={{
                    fontSize: "35px",
                    height: "70px",
                    width: "55%",
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor: "#721891",
                  }}
                  icon={<img src={yape} style={{ height: "60px" }} alt="" />}
                ></Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setOpenPlin(true)}
                  style={{
                    fontSize: "35px",
                    height: "70px",
                    width: "55%",
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor: "#299EEB",
                  }}
                  icon={<img src={plin} style={{ height: "60px" }} alt="" />}
                ></Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  // onClick={showCulqi}
                  onClick={clickBtnCulqi}
                  style={{
                    fontSize: "35px",
                    height: "70px",
                    width: "55%",
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor: "#010101",
                  }}
                >
                  Billetera móvil Culqi
                </Button>
              </Col>
            </Row>

            <h1
              onClick={() => volverT()}
              style={{
                fontSize: "30px",
                textAlign: "center",
                paddingTop: "100px",
              }}
            >
              <a>Volver</a>
            </h1>
          </div>
        ) : (
          <div style={{ padding: "30px 0 0 0 " }}>
            <h1
              style={{
                textAlign: "center",
                fontSize: "45px",
                fontWeight: "500",
                paddingBottom: "100px",
              }}
            >
              Seleccion una forma de pago
            </h1>
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setOpenIns(true)}
                  style={{
                    fontSize: "35px",
                    height: "70px",
                    width: "55%",
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor: "#A50B70",
                  }}
                  icon={<CreditCardOutlined style={{ paddingRight: "15px" }} />}
                >
                  Tarjeta de Credito/Debito
                </Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setOpenYape(true)}
                  style={{
                    fontSize: "35px",
                    height: "70px",
                    width: "55%",
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor: "#721891",
                  }}
                  icon={<img src={yape} style={{ height: "60px" }} alt="" />}
                ></Button>
              </Col>
              <Col span={24} style={{ textAlign: "center" }}>
                <Button
                  onClick={() => setOpenPlin(true)}
                  style={{
                    fontSize: "35px",
                    height: "70px",
                    width: "55%",
                    borderRadius: "20px",
                    color: "white",
                    backgroundColor: "#299EEB",
                  }}
                  icon={<img src={plin} style={{ height: "60px" }} alt="" />}
                ></Button>
              </Col>
            </Row>

            <h1
              onClick={() => volverT()}
              style={{
                fontSize: "30px",
                textAlign: "center",
                paddingTop: "100px",
              }}
            >
              <a>Volver</a>
            </h1>
          </div>
        )}
        <div style={{ position: "absolute", bottom: "5px" }}>
          <h1
            style={{
              paddingTop: "100px",
              textAlign: "center",
              fontSize: "35px",
            }}
          >
            Aceptamos todas las tarjetas de debito y credito.
          </h1>
          <img style={{ width: "100%", height: "100px" }} src={Tarjetas} alt="" />
        </div>
      </Drawer>
      {openPlin ? (
        <DrawerPlin
          pFinal={pFinal}
          openPlin={openPlin}
          setOpenPlin={setOpenPlin}
          cantidad={cantidad}
          listFinal={listFinal}
        />
      ) : null}
      {openYape ? (
        <DrawerYape
          pFinal={pFinal}
          openYape={openYape}
          setOpenYape={setOpenYape}
          cantidad={cantidad}
          listFinal={listFinal}
        />
      ) : null}
      {openIns ? (
        <DrawerInstrucciones
          pFinal={pFinal}
          openIns={openIns}
          setOpenIns={setOpenIns}
          cantidad={cantidad}
          setOpenPrint={setOpenPrint}
          listFinal={listFinal}
        />
      ) : null}
      {openPrint ? (
        <DrawerPrint
          pFinal={pFinal}
          openPrint={openPrint}
          setOpenPrint={setOpenPrint}
          cantidad={cantidad}
          listFinal={listFinal}
        />
      ) : null}
    </div>
  );
}

export default DrawerParaLlevar;
