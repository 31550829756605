import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../authentication';
import { httpClient } from '../../util/api';
import Cuerpo from './cuerpo';
import { useHistory } from "react-router-dom";
import TipoPedido from '../main/TipoPedido';
import { getUrlImage } from '../../util/functions';
// import ComponentBanner from '../Maintenance/banners/ComponentBanner';

const Index = () => {

  const UserLoggedIn = JSON.parse(localStorage.getItem("UserLoggedIn"));
  console.log(`UserLoggedIn`, UserLoggedIn);
  const history = useHistory();

  const [dataBanners, setDataBanners] = useState({});
  const [openNombre, setOpenNombre] = useState();

  const [openTipoP, setOpenTipoP] = useState(false);

  const idEstablecimiento = UserLoggedIn.data[0].idEstableciomiento;
  const logo = UserLoggedIn.imagenes[0]

  const { userSignOut } = useAuth();

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const traerBanners = async () => {
    const banner = await httpClient.post(`/negocio/conf/mostrarBanner`, { idEstablecimiento });
    console.log(banner, 'data del baner');
    if (banner.data.success) {
      setDataBanners(banner.data.data);
    }
  }

  useEffect(() => {
    traerBanners();
    return () => {
      setDataBanners(null);
    };
  }, []);

  return (
    <div>
      {
        openTipoP ?
          <TipoPedido setOpen={setOpenTipoP} setOpenNombre={setOpenNombre} />
          : null
      }

      <div style={{ position: 'relative', zIndex: '10', visibility: openTipoP ? 'hidden' : 'visible' }}>
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
          onClick={() => onLogoutClick()}
          style={{ position: "absolute", top: "0", zIndex: '5', width: '150px', height: '150px', margin: '30px 0 0 30px', borderRadius: '100px' }}
          src={getUrlImage(logo.urlImagen)}
        />
        <Carousel autoplay dots={false}>
          {
            dataBanners.banners ?
              dataBanners.banners.map((element, index) => {
                return (
                  <div key={index} style={{ position: 'relative', zIndex: '10', width: '100%' }} >
                    <img style={{ width: '100%', height: '300px', zIndex: '5', }} src={getUrlImage(element.urlImagen)} alt='banners' />
                  </div>
                )
              })
              : null
          }
        </Carousel>
        <div className='main-order' style={{ position: "absolute", top: "90%", width: "100%", height: "1000px" }}>
          <div style={{ width: "98%", height: "150px", margin: "0 auto", borderRadius: "30px 30px 0 0", backgroundColor: '#F3F3F3' }}>
            <Cuerpo setOpenTipoP={setOpenTipoP} openNombre={openNombre} setOpenNombre={setOpenNombre} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index;

