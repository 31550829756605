import { API, API_PRINT } from "../../util/api";
import qz from 'qz-tray';

export function apiPrintSignin() {

  const params = {
    "username": "modulopos",
    "password": "75wl5gxcm4xlt8",
    "variant_code": "EtI3oDjVbKasPYOZ5s1G"
  };

  return API_PRINT.post('signin', params).then(({ data }) => {
    console.log('apiPrintSignin');
    if (data.code === 200) {
      localStorage.setItem('api_print_signin', JSON.stringify(data.data));
      return data.data;
    } else {
      console.log(data.error);
    }
  }).catch(function (error) {
    console.log(error.message);
  });
}

export function apiPrintLogin() {

  const resp = apiPrintSignin();

  const params = {
    "username": "gabanto",
    "password": "1234"
  };

  resp.then(function (resp) {

    const options = {
      headers: { 'App-Authorization': resp.token }
    };

    API_PRINT.post('user/login', params, options).then(({ data }) => {
      console.log('apiPrintLogin');
      if (data.code === 200) {
        localStorage.setItem('api_print_user', JSON.stringify(data.data));
        console.log(data.data);
      } else {
        console.log(data.error);
      }
    }).catch(function (error) {
      console.log(error.message);
    });

  });
}

export async function apiPrintPrint() {
  const api_print_signin = JSON.parse(localStorage.getItem("api_print_signin"));
  const api_print_user = JSON.parse(localStorage.getItem("api_print_user"));

  const options = {
    headers: { 'App-Authorization': api_print_signin.token, 'Authorization': api_print_user.token }
  };

  let path = `sale/escPos/7611GFCR31UYEQ?raw_printer_id=21&print_series=1&print_payments=1`;

  try {
    const response = await API_PRINT.get(path, options);
    const data = response.data;
    console.log(data);
    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function requestPrint(dataPrint, objByConfig = null, _callback = null, cortar = false, dataComanda) {

  // let printerComanda = `POS80MIAUNT`; //POS80
  let printerComanda = `TP300 PRO-kiosco`; //POS80
  let printerKiosk = `POS80-KIOSCO`;
  let config = null;

  let printer = printerKiosk;

  try {
    qz.security.setCertificatePromise(function (resolve, reject) {
      fetch(`${API}/files/cert`, { cache: "no-store", headers: { "Content-Type": "text/plain" } }).then(function (data) {
        data.ok ? resolve(data.text()) : reject(data.text());
      });
    });

    qz.security.setSignaturePromise(function (toSign) {
      return function (resolve, reject) {
        fetch(`${API}/qz-tray/sign-message?request=${toSign}`, { cache: "no-store", headers: { "Content-Type": "text/plain" } }).then(function (data) {
          data.ok ? resolve(data.text()) : reject(data.text());
        });
      };
    });

    qz.websocket.connect().then(() => {
      console.log(`connect ${printer}`);

      if (objByConfig === null) {
        console.log('NOT objByConfig');
        config = qz.configs.create(printer);
      } else {
        console.log('objByConfig');
        config = qz.configs.create(printer, objByConfig);
      }

      console.log(dataPrint);
      qz.print(config, dataPrint).then(async () => {
        if (cortar) {
          const configCut = qz.configs.create(printer, {
            encoding: 'ISO-8859-1',
            altPrinting: true,
          });

          const dataCut = [
            "\u001ba\u0001",
            "\n",
            "\n",
            "\u001dVA2"
          ];

          qz.print(configCut, dataCut).then(async () => {
            console.log(`connect ${printerComanda}`);
            console.log(`dataComanda`, dataComanda);
            const configComanda = qz.configs.create(printerComanda);
            qz.print(configComanda, dataComanda).then(async () => {
              const configCut = qz.configs.create(printerComanda, {
                encoding: 'ISO-8859-1',
                altPrinting: true,
              });
              qz.print(configCut, dataCut).then(async () => {
                qz.websocket.disconnect();
                if (_callback !== null) {
                  _callback();
                }
              }).catch((error) => { throw new Error(error); });
            });
          }).catch((error) => { throw new Error(error); });
        } else {
          qz.websocket.disconnect();
        }
      });
    }).catch((error) => { throw new Error(error); });
  } catch (error) {
    throw new Error(error);
  }
}
