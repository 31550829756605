import { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
import { httpClient } from "../../../util/api";
// import { onNavStyleChange } from "../../../../src/appRedux/actions";
// import {
//   LAYOUT_TYPE_FRAMED,
//   NAV_STYLE_ABOVE_HEADER,
//   NAV_STYLE_BELOW_HEADER,
//   NAV_STYLE_DARK_HORIZONTAL,
//   NAV_STYLE_DEFAULT_HORIZONTAL,
//   NAV_STYLE_DRAWER,
//   NAV_STYLE_FIXED,
//   NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
//   NAV_STYLE_MINI_SIDEBAR,
//   NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
//   NAV_STYLE_NO_HEADER_MINI_SIDEBAR
// } from "../../../constants/ThemeSetting";
import moment from 'moment';


export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setLoading(true);
    setError('');
  };

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  // const dispatch = useDispatch();
  // const onNavStyleChanges = (navStyle) => {
  //   dispatch(onNavStyleChange(navStyle));
  // };

  const userLogin = (user, callbackFun) => {
    console.log(user);
    fetchStart();
    httpClient.post('login', user).then(({ data }) => {
      if (data.success) {
        fetchSuccess();
        localStorage.setItem('token_activo', JSON.stringify(data.data));
        localStorage.setItem('UserLoggedIn', JSON.stringify(data.data));

        // if (data.data[0].idTipoUsuario == 3) {
        //   onNavStyleChanges(NAV_STYLE_FIXED);
        //   console.log(data.data[0].idTipoUsuario, 'si entra');
        // }

        getAuthUser(data.data);
        if (callbackFun) callbackFun();
      } else {
        fetchError(data.error);
      }
    })
      .catch(function (error) {
        fetchError(error.message);
      });
  };


  const userSignup = (user, callbackFun) => {
    const fecha = moment(user.fechaNacimiento).format('YYYY-MM-DD');
    const data = new FormData();
    data.append('nombre', user.nombre);
    data.append('apellido', user.apellido);
    data.append('celular', user.celular);
    data.append('direccion', user.direccion);
    data.append('correo', user.correo);
    data.append('fechaNacimiento', fecha);
    data.append('usuario', user.usuario);
    data.append('clave', user.clave);
    data.append('urlFoto', user.imagen);

    // console.log(moment(user.fechaNacimiento).format('YYYY-MM-DD'));
    fetchStart();
    httpClient
      .post('cliente/registroCliente', data)
      .then((data) => {
        console.log({ data })
        if (data.data.success) {
          console.log(data.data.data[0], 'token');
          fetchSuccess();
          localStorage.setItem('token_activo', JSON.stringify(data.data.data[0]));
          // localStorage.setItem('token_activo', data.token.access_token);
          // httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          getAuthUser(data.data.data);
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const userSignupPyme = (user, callbackFun) => {

    const data = new FormData();
    data.append('razonSocial', user.razonSocial);
    data.append('ruc', user.ruc);
    data.append('celular', user.celular);
    data.append('direccion', user.direccion);
    data.append('descripcion', user.descripcion);
    data.append('urlImagen', user.imagenDoc);
    data.append('usuario', user.usuario);
    data.append('clave', user.clave);
    data.append('urlFoto', user.imagen);

    console.log(user);
    fetchStart();
    httpClient
      .post('pyme/registroPyme', data)
      .then((data) => {
        console.log({ data })
        if (data.data.success) {
          console.log(data.data.data, 'token');
          fetchSuccess();
          localStorage.setItem('token_activo', JSON.stringify(data.data.data[0]));
          // localStorage.setItem('token_activo', data.token.access_token);
          // httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
          getAuthUser(data.data.data);
          if (callbackFun) callbackFun();
        } else {
          fetchError(data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    fetchSuccess();
    localStorage.removeItem('token_activo');
    setAuthUser(false);
  };

  const getAuthUser = (data) => {
    fetchStart();
    fetchSuccess();
    setAuthUser(data);
  };

  /*  const getAuthUser = () => {
     fetchStart();
     httpClient
       .post('auth/me')
       .then(({ data }) => {
         if (data.user) {
           fetchSuccess();
           setAuthUser(data.user);
         } else {
           fetchError(data.error);
         }
       })
       .catch(function (error) {
         httpClient.defaults.headers.common['Authorization'] = '';
         fetchError(error.message);
       });
   }; */

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    const token = localStorage.getItem('token_activo');
    setAuthUser(JSON.parse(token));
    setLoadingUser(false);
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignupPyme,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };
};
