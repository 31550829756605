import { Button, Col, Divider, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { httpClient } from "../../util/api";
import ModalProducto from "./ModalProducto";
import "react-modern-drawer/dist/index.css";
import "./contenido.css";
import DrawerNombre from "../main/DrawerNombre";
import Carousel from "react-multi-carousel";
import ScrollContainer from "react-indiana-drag-scroll";
import { useHistory } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
// import { DivSub } from "../../components/Personalizado/DivSubCate";
import ComponentSubCate from "./ComponentSubCate";
import DrawerParaLlevar from "../main/DrawerParaLlevar";
import DrawerComprobante from "../main/DrawerComprobante";
import CardCategorias from "../../components/Personalizado/CardCategoria/cardCategorias";
import { clearOrderData, getUrlImage } from "../../util/functions";

const Cuerpo = ({ setOpenTipoP, openNombre, setOpenNombre }) => {
  const user = JSON.parse(localStorage.getItem("token_activo"));
  // console.log(idNegocio);
  const [idSubCategoria, setIdSubCategoria] = useState(null);
  const [categorias, setCategorias] = useState();
  const [categorias2, setCategorias2] = useState();
  const [subCategorias, setSubCategorias] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  const [pasarelas, setPasarelas] = useState();
  const [productos, setProductos] = useState([]);
  const [dataProducto, setDataProducto] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const [cantidad, setCantidad] = useState(1);
  const [estadoBtn, setEstadoBtn] = useState(true);
  // const [mostrarDetalless, setMostrarDetalless] = useState(false);
  const [pagar, setPagar] = useState(false);
  const [limpiar, setLimpiar] = useState(false);
  const [pFinal, setPFinal] = useState(0.0);
  const history = useHistory();

  const [mostrarComprobante, setMostrarComprobante] = useState(false);

  const [detalleProducto, setDetalleProducto] = useState();
  // lista
  const [listOpcionUnica, setListOpcionUnica] = useState({});
  const [listOpcionMult, setListOpcionMult] = useState({});
  const [listOpcionAdi, setListOpcionAdi] = useState({});

  const [listFinal, setListFinal] = useState([]);
  console.log(listOpcionAdi, "adicionales");
  console.log(listOpcionMult, "miltiple");
  console.log(listOpcionUnica, "unica");
  console.log(listFinal, "final");

  const mostrarSubCatergoria = async (idCategoria, nombreCategoria) => {
    setProductos(null);
    setSelectedSubCategory("");
    setSubCategorias(null);
    setSelectedCategory(nombreCategoria);
    const subcategorias = await httpClient.post(`/getSubCategoria`, {
      idCategoria,
    });
    // console.log(`subcategorias`, subcategorias.data.data);
    setSubCategorias(subcategorias.data.data);
    const subcategoria = subcategorias.data.data[0];
    // console.log(`subcategoria`, subcategoria);
    mostrarProductos(
      subcategoria.idSubCategoria,
      subcategoria.nombreSubCategoria
    );
  };

  const mostrarProductos = async (idSubCategoria, nombreSubCategoria) => {
    setSelectedSubCategory(nombreSubCategoria);

    const respProductos = await httpClient.post(`/getProductos`, {
      idSubCategoria,
    });

    console.log(`productos`, respProductos);
    if (respProductos.data.success) {
      setProductos(respProductos.data.data);
    } else {
      setProductos([]);
    }
    setIdSubCategoria(idSubCategoria);
  };

  const traerCategorias = async () => {
    const idNegocio = user.data[0].idNegocio;
    console.log(idNegocio, "categorias");
    const categorias = await httpClient.post(`/getCategoria`, { idNegocio });
    console.log(categorias.data, "categorias");

    if (categorias.data.success) {
      console.log(categorias.data.data, "categorias");

      if (categorias.data.data.length >= 6) {
        let mitad = Math.floor(categorias.data.data.length / 1.5);
        console.log(mitad);
        let inicio = categorias.data.data.slice(0, mitad);
        let final = categorias.data.data.slice(mitad);
        console.log(inicio);
        console.log(final);
        setCategorias(inicio);
        setCategorias2(final);
      } else {
        setCategorias(categorias.data.data);
      }

      // const categoria = categorias.data.data[0];
      // console.log(`categoria`, categoria);
      // mostrarSubCatergoria(
      //   categoria.idCategoria,
      //   categoria.nombreCategoria
      // );
    }
  };

  const traerPasarelas = async () => {
    const idEstablecimiento = user.data[0].idEstableciomiento;
    const pasarela = await httpClient.post(`/pasarelaPagos/listar`, {
      idEstablecimiento,
    });

    if (pasarela.data.success) {
      setPasarelas(pasarela.data.data);
    }
  };
  // const mostrarDetalles = (idProducto) => {
  //     setMostrarDetalless(true);
  // }

  useEffect(() => {
    traerCategorias();
    traerPasarelas();
    console.log(listFinal.length, "aver use");
    if (listFinal.length >= 1) {
      setEstadoBtn(false);
      let precioF = 0;
      listFinal.forEach((element) => {
        console.log(element, "useefect");
        const c = Object.values(element.adicional);
        let f = 0;
        c.forEach((element) => {
          element.forEach((e) => {
            console.log(e, "iteracion");
            f = f + e.precio * e.cantidad;
          });
        });
        console.log(f, "precio adicional");
        precioF = precioF + element.precio * cantidad + f;
      });
      setPFinal(precioF.toFixed(2));
      console.log(precioF.toFixed(2), "holaaa");
    } else {
      setEstadoBtn(true);
    }
    // addListFinal();
  }, [isOpen, listFinal]);

  const mostrarDetallesProducto = async (data) => {
    // console.log(`mostrarDetallesProducto => producto`, data);
    setDataProducto(data);
    const producto = await httpClient.post(`/detalle/productos`, {
      idProducto: data.idProducto,
    });

    if (producto.status === 200) {
      // console.log(`respuesta DetallesProducto`, producto);
      if (producto.data.success) {
        // console.log(`DetallesProducto => detalle`, producto.data.data);
        setDetalleProducto(producto.data.data);
      } else {
        console.log(`el producto no tiene detalle`);
        setDetalleProducto(null);
      }
      setIsOpen(true);
    } else {
      alert("Ocurrio un error");
    }
  };

  const pedido = JSON.parse(sessionStorage.getItem("pedido"));
  console.log(pedido, "pedidos");

  const Limpiar = () => {
    console.log("holaa");
    clearOrderData();
    setLimpiar(true);
    setListFinal([]);
    setPFinal(0);
  };

  const salir = () => {
    history.push("/main");
    clearOrderData();
  };

  const borrarItem = (e) => {
    console.log(e, "datos");
    let a = 0;
    e.adicional.forEach((element) => {
      element.forEach((e) => {
        a = a + e.precio * e.cantidad;
      });
    });

    const menos = e.precio + a;
    console.log(listFinal, "datos enteros");
    setPFinal(pFinal - menos);
    const filteredLibraries = listFinal.filter((item) => item !== e);
    console.log(filteredLibraries, "data eliminado");
    setListFinal(filteredLibraries);
  };

  return (
    <div style={{ marginTop: "-60px", padding: "5px" }}>
      <Row>
        {/** (START) LISTA PRODUCTOS */}
        <Col
          sm={14}
          lg={14}
          xl={14}
          md={14}
          xs={14}
          style={{ padding: "20px" }}
        >
          <Col sm={24} lg={24} xl={24} md={24} xs={24}>
            <Space size="5">
              <h1
                style={{
                  fontSize: "40px",
                  fontWeight: "600",
                  margin: "0 0 20px 20px",
                }}
              >
                ¡Hola! <span style={{ fontWeight: "400" }}>¿Qué tal?</span>
              </h1>
            </Space>
          </Col>

          {categorias ? (
            <Carousel
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 3,
                  slidesToSlide: 3, // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                  slidesToSlide: 2, // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1, // optional, default to 1.
                },
              }}
            >
              {categorias.map((element, index) => {
                return (
                  <Col
                    key={`categorias-${index}`}
                    style={{
                      padding: "0px 10px 0px 0px",
                      marginBottom: "24px",
                    }}
                  >
                    <CardCategorias
                      mostrarSubCatergoria={mostrarSubCatergoria}
                      data={element}
                      user={user}
                    />
                  </Col>
                );
                // }
              })}
            </Carousel>
          ) : null}
          {categorias2 ? (
            <Carousel
              responsive={{
                desktop: {
                  breakpoint: { max: 3000, min: 1024 },
                  items: 3,
                  slidesToSlide: 3, // optional, default to 1.
                },
                tablet: {
                  breakpoint: { max: 1024, min: 464 },
                  items: 2,
                  slidesToSlide: 2, // optional, default to 1.
                },
                mobile: {
                  breakpoint: { max: 464, min: 0 },
                  items: 1,
                  slidesToSlide: 1, // optional, default to 1.
                },
              }}
            >
              {categorias2.map((element, index) => {
                return (
                  <Col key={`categorias2-${index}`} style={{ padding: "10px" }}>
                    <CardCategorias
                      mostrarSubCatergoria={mostrarSubCatergoria}
                      data={element}
                      user={user}
                    />
                  </Col>
                );
                // }
              })}
            </Carousel>
          ) : null}

          {subCategorias ? (
            <div style={{ marginBottom: "24px" }}>
              <h1
                style={{ fontSize: "22px", fontWeight: "400", margin: "0px" }}
              >
                {selectedCategory}:
              </h1>
              <Divider
                style={{
                  backgroundColor: "#808080",
                  borderTopWidth: "2px",
                  margin: "3px 0px 12px 0px",
                }}
              />
              <Carousel
                responsive={{
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                    slidesToSlide: 3, // optional, default to 1.
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                    slidesToSlide: 2, // optional, default to 1.
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    slidesToSlide: 1, // optional, default to 1.
                  },
                }}
              >
                {subCategorias.map((element, index) => {
                  return (
                    <ComponentSubCate
                      key={`subCategorias-${index}`}
                      element={element}
                      mostrarProductos={mostrarProductos}
                      idSubCategoria={idSubCategoria}
                    />
                  );
                })}
              </Carousel>
            </div>
          ) : null}

          <div>
            <h1 style={{ fontSize: "22px", fontWeight: "400", margin: "0px" }}>
              {selectedSubCategory}
            </h1>
            <Divider
              style={{
                backgroundColor: "#808080",
                borderTopWidth: "2px",
                margin: "3px 0px 12px 0px",
              }}
            />

            {productos && (
              <div>
                {productos.length > 0 ? (
                  <ScrollContainer style={{ width: "100%", padding: "0 20px" }}>
                    <Row className="wrap-products-list">
                      {productos.map((element, index) => {
                        return (
                          <Col
                            key={`productos-${index}`}
                            className="wrap-products-item"
                            sm={8}
                            lg={8}
                            xl={8}
                            md={8}
                            xs={8}
                          >
                            <div
                              onClick={() => mostrarDetallesProducto(element)}
                              className="wrap-products-item__card"
                              style={{ height: "315px" }}
                            >
                              <div
                                style={{
                                  textAlign: "center",
                                  paddingBottom: "10px",
                                }}
                              >
                                <img
                                  src={getUrlImage(element.urlImagen)}
                                  style={{ height: "150px", width: "150px" }}
                                  alt="Producto a seleccionar"
                                />
                              </div>
                              <div style={{ height: "60px" }}>
                                <h2 style={{ fontWeight: "600" }}>
                                  {element.nombre}
                                </h2>
                              </div>

                              {element.descripcion ? (
                                <div style={{ height: "30px" }}>
                                  <h2 style={{ fontSize: "15px" }}>
                                    {element.descripcion.substr(0, 30) + "..."}
                                  </h2>
                                </div>
                              ) : null}

                              <div style={{ height: "30px" }}>
                                <h2
                                  style={{
                                    fontSize: "25px",
                                    fontWeight: "500",
                                    paddingTop: "10px",
                                  }}
                                >
                                  S/ {element.precio}
                                </h2>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </ScrollContainer>
                ) : (
                  <div style={{ paddingTop: "100px", textAlign: "center" }}>
                    <h1> </h1>
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
        {/** (END) LISTA PRODUCTOS */}
        {/** (START) LISTA DE LA ORDEN */}
        <Col
          sm={10}
          lg={10}
          xl={10}
          md={10}
          xs={10}
          style={{
            background: "white",
            padding: "0",
            margin: "-4px 0 0 -10px",
            height: "85vh",
            borderRadius: "0 30px 0 0",
          }}
        >
          <h2
            style={{
              fontSize: "30px",
              fontWeight: "600",
              margin: "30px 0 0 20px",
            }}
          >
            Mi orden
          </h2>
          <Divider />
          <ScrollContainer
            style={{
              height: "1200px",
              width: "100%",
              padding: "0 40px  0 15px",
            }}
          >
            {listFinal.map((element, index) => {
              let a = 0;
              a = element.precio + a;

              return (
                <div key={`listFinal-${index}`}>
                  <Row style={{ padding: "0 0 0 10px" }}>
                    <Col span={2}>
                      <h1>{element.cantidad}</h1>
                    </Col>
                    <Col span={12}>
                      <h1>{element.nombre}</h1>
                    </Col>
                    <Col span={6}>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: "30px",
                          fontWeight: "500",
                        }}
                      >
                        {"s/" + element.precio}
                      </h1>
                    </Col>
                    <Col span={4} style={{ textAlign: "end" }}>
                      <Button
                        onClick={() => borrarItem(element)}
                        style={{
                          marginLeft: "20px",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50px",
                          borderColor: "red",
                          borderWidth: "3px",
                        }}
                        icon={
                          <DeleteOutlined
                            style={{ fontSize: "25px", color: "red" }}
                          />
                        }
                      ></Button>
                    </Col>
                  </Row>

                  {element.unico.map((e, index) => {
                    console.log(e.cuerpo.toString(), "string de unico");
                    return (
                      <h1
                        key={`Unico-${index}`}
                        style={{ color: "#AFAFAF", paddingLeft: "30px" }}
                      >
                        {e.titulo + ":" + "(" + e.cuerpo.toString() + ")"}
                      </h1>
                    );
                  })}
                  {element.multiple.map((e, index) => {
                    console.log(e.cuerpo.toString(), "string de multiple");
                    return (
                      <h1
                        key={`Multiples-${index}`}
                        style={{ color: "#AFAFAF", paddingLeft: "30px" }}
                      >
                        {e.titulo + ":" + "(" + e.cuerpo.toString() + ")"}
                      </h1>
                    );
                  })}
                  {element.adicional.map((e, index) => {
                    return (
                      <div key={`Adicionales-${index}`}>
                        <h1 style={{ color: "#AFAFAF", paddingLeft: "30px" }}>
                          Adicionales
                        </h1>
                        {e.map((a, index) => {
                          console.log(parseFloat(a.precio), "precio parceado");
                          return (
                            <Row
                              key={`Adicionales-a-${index}`}
                              style={{
                                color: "#AFAFAF",
                                paddingLeft: "30px",
                                marginTop: "-10px",
                              }}
                            >
                              <Col span={2}>
                                <h1
                                  style={{
                                    color: "#AFAFAF",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {a.cantidad}
                                </h1>
                              </Col>
                              <Col span={12}>
                                <h1
                                  style={{
                                    color: "#AFAFAF",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {a.nombre}
                                </h1>
                              </Col>
                              <Col span={4}>
                                <h1
                                  style={{
                                    textAlign: "center",
                                    fontSize: "30px",
                                    color: "#AFAFAF",
                                    fontWeight: "500",
                                  }}
                                >
                                  {"s/" +
                                    (parseFloat(a.precio) * a.cantidad).toFixed(
                                      2
                                    )}
                                </h1>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                    );
                  })}
                  <div
                    style={{
                      height: "2px",
                      backgroundColor: "#AFAFAF",
                      width: "100%",
                      marginBottom: "15px",
                    }}
                  ></div>
                </div>
              );
            })}
          </ScrollContainer>

          <Row>
            <Col span={12}>
              <h1 style={{ fontSize: "30px", fontWeight: "500" }}>Total</h1>
            </Col>
            <Col span={12}>
              <h1
                style={{
                  fontSize: "30px",
                  fontWeight: "500",
                  textAlign: "end",
                  paddingRight: "30px",
                }}
              >
                {"s/" + pFinal}
              </h1>
            </Col>
          </Row>

          <Divider />
          <div style={{ textAlign: "center" }}>
            <Button
              disabled={estadoBtn}
              onClick={() => setOpenTipoP(true)}
              style={{
                backgroundColor: "#A50B70",
                fontSize: "45px",
                color: "white",
                height: "100px",
                width: "95%",
              }}
            >
              Ordenar
            </Button>

            <div style={{ marginTop: "15px" }}>
              <Button
                style={{
                  backgroundColor: "#A50B70",
                  fontSize: "30px",
                  color: "white",
                  height: "70px",
                  width: "70%",
                }}
                onClick={() => Limpiar()}
              >
                Limpiar Orden
              </Button>
            </div>
            <div style={{ marginTop: "15px" }}>
              <Button
                style={{
                  backgroundColor: "#A50B70",
                  fontSize: "30px",
                  color: "white",
                  height: "70px",
                  width: "45%",
                }}
                onClick={() => salir()}
              >
                Salir
              </Button>
            </div>
          </div>
        </Col>
        {/** (END) LISTA DE LA ORDEN */}
      </Row>

      {isOpen ? (
        <ModalProducto
          detalleProducto={detalleProducto}
          listFinal={listFinal}
          listOpcionAdi={listOpcionAdi}
          setListOpcionAdi={setListOpcionAdi}
          listOpcionMult={listOpcionMult}
          setListOpcionMult={setListOpcionMult}
          listOpcionUnica={listOpcionUnica}
          setListOpcionUnica={setListOpcionUnica}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          dataProducto={dataProducto}
          setCantidad={setCantidad}
          cantidad={cantidad}
        />
      ) : null}

      {openNombre ? (
        <DrawerNombre
          cantidad={cantidad}
          openNombre={openNombre}
          setOpenNombre={setOpenNombre}
          setPagar={setPagar}
          setMostrarComprobante={setMostrarComprobante}
        />
      ) : null}
      {pagar ? (
        <DrawerParaLlevar
          pFinal={pFinal}
          pagar={pagar}
          setPagar={setPagar}
          cantidad={cantidad}
          listFinal={listFinal}
        />
      ) : null}
      {mostrarComprobante ? (
        <DrawerComprobante
          pFinal={pFinal}
          listFinal={listFinal}
          mostrarComprobante={mostrarComprobante}
          setPagar={setPagar}
          setMostrarComprobante={setMostrarComprobante}
        />
      ) : null}
    </div>
  );
};

export default Cuerpo;
