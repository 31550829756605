import React from "react";
import { Popover } from "antd";
import { useAuth } from "../../authentication";
import { useHistory } from "react-router-dom";

const UserProfile = () => {
  const { userSignOut } = useAuth();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('token_activo'));


  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={onLogoutClick}>Cerrar Sesion</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row" style={{ width: '100%' }}>
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        {/* <Avatar src={"https://via.placeholder.com/150"} className="gx-size-40 gx-pointer gx-mr-3" alt="" /> */}
        <div style={{ textAlign: 'start', width: '100%' }}>
          <span className="gx-avatar-name">{user.nombre ? user.nombre : 'administrador'}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" /></span>
        </div>
      </Popover>
    </div>
  )
};

export default UserProfile;
