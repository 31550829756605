import React, { useEffect, useState } from "react";
import { DivSub, TitleSub } from "../../components/Personalizado/DivSubCate";
import { getUrlImage } from "../../util/functions";

function ComponentSubCate(props) {
  const [active, setActive] = useState(true);

  const { element, mostrarProductos, idSubCategoria } = props;

  const cambiarColor = () => {
    mostrarProductos(element.idSubCategoria, element.nombreSubCategoria);
  };

  useEffect(() => {
    setActive(
      idSubCategoria ? idSubCategoria !== element.idSubCategoria : true
    );
  }, [idSubCategoria]);

  return (
    <DivSub
      primary={"white"}
      secundary={"#A50B70"}
      active={active}
      onClick={() => cambiarColor()}
      style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
    >
      <img
        src={getUrlImage(element.urlImagen)}
        style={{ borderRadius: "50%", height: "130px", width: "130px  " }}
        alt="Producto Subcategoria"
      />
      <TitleSub primary={"black"} secundary={"#F9E300"} active={active}>
        {element.nombreSubCategoria}
      </TitleSub>
    </DivSub>
  );
}

export default ComponentSubCate;
