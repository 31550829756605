import React from "react";
import {Avatar, Popover} from "antd";
import {useAuth} from "../../authentication";
import {useHistory, Link} from "react-router-dom";
import { urlFoto } from "../../util/api";

const UserInfo = () => {
  const {userSignOut} = useAuth();
  const history = useHistory();

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const nivel = JSON.parse(localStorage.getItem("token_activo"));
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <Link to="/miCuenta">
          <li>Mi Cuenta</li>
        </Link>
      {/* <li>Mi cuenta</li> */}
      {/* <li>Connections</li> */}
      <li onClick={onLogoutClick}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
      <span>{nivel.idTipoUsuario == 1 ? nivel.nombre: nivel.razonSocial}</span>
       <Avatar src={nivel.urlFoto? urlFoto+nivel.urlFoto : "https://via.placeholder.com/150"} className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  );
};

export default UserInfo;
