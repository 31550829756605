import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { getUrlImage } from "../../util/functions";

const Index = () => {
  const UserLoggedIn = JSON.parse(localStorage.getItem("UserLoggedIn"));
  const history = useHistory();
  // console.log(`UserLoggedIn`, UserLoggedIn);

  const clickMakeAnOrder = () => {
    history.push("/principal");
  };

  return (
    <div>
      <img
        src={getUrlImage(UserLoggedIn.imagenes[1].urlImagen)}
        alt="cargando"
        width={"100%"}
        height={"100%"}
        style={{ position: "absolute" }}
      />

      <div
        style={{
          position: "absolute",
          paddingLeft: "28%",
          width: "100%",
          bottom: "2%",
        }}
      >
        <Button
          onClick={() => clickMakeAnOrder()}
          style={{
            width: "65%",
            height: "150px",
            fontSize: "50px",
            fontWeight: "600",
            borderRadius: "15px",
          }}
        >
          Realizar Pedido
        </Button>
      </div>
      <div
        style={{ position: "absolute", width: "100%", top: "2%", left: "3%" }}
      >
        <img
          src={getUrlImage(UserLoggedIn.imagenes[0].urlImagen)}
          alt="cargando"
          width={"250px"}
          height={"100%"}
        />
      </div>
    </div>
  );
};
export default Index;
