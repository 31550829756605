import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
// import ConectorPlugin from './ConectorPlugin';
// import a from './ConectorPlugin.js';
// const qz = require("qz-tray");
import qz from 'qz-tray';
import EscPosEncoder from 'esc-pos-encoder';
import { forEach } from 'lodash';


function ComponenPrint({ listFinal, pFinal }) {
  console.log(listFinal, 'lista');

  const user = JSON.parse(localStorage.getItem("token_activo"));
  const logo = user.imagenes[0].urlImagen;
  // console.log(logo);
  const fecha = new Date();
  const fechaFinal = fecha.toISOString().split('T')[0];


  const conectar = () => {
    qz.websocket.connect().then(function () {
      alert("Connected!");
      console.log('hola');
    });
  }

  const imprimir = () => {
    // const a=["hola",'como','estas']
    var  myJSONText= JSON.stringify(listFinal);
    console.log(myJSONText,'list final');

    // console.log(`http://207.180.246.8:8080/print.html?data=${myJSONText}&logo=${logo}&precio=${pFinal}`);

    qz.websocket.connect().then(function () {
      
      return qz.printers.find("POS80");              // Pass the printer name into the next Promise
    }).then(function (printer) {
      var config = qz.configs.create(printer);
      var data = [{
        type: 'pixel',
        format: 'html',
        flavor: 'file', // or 'plain' if the data is raw HTML
        data: `http://207.180.246.8:8080/print.html?data=${myJSONText}&logo=${logo}&precio=${pFinal}`,
     }];
      // var data = [
      //   { type: 'raw', format: 'image', flavor: 'file', data: logo, options: { language: "ESCPOS", dotDensity: 'double' } },
      //   '\x1B' + '\x40',          // init
      //   '\x1B' + '\x61' + '\x31', // center align
      //   'Beverly Hills, CA  90210' + '\x0A',
      //   '\x0A',                   // line break
      //   'www.qz.io' + '\x0A',     // text and line break
      //   '\x0A',                   // line break
      //   '\x0A',                   // line break
      //   'May 18, 2016 10:30 AM' + '\x0A',
      //   '\x0A',                   // line break
      //   '\x0A',                   // line break    
      //   '\x0A',
      //   'Transaction # 123456 Register: 3' + '\x0A',
      //   '\x0A',
      //   '\x0A',
      //   '\x0A',
      //   '\x1B' + '\x61' + '\x30', // left align
      //   'Baklava (Qty 4)       9.00' + '\x1B' + '\x74' + '\x13' + '\xAA', //print special char symbol after numeric
      //   '\x0A',
      //   'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX' + '\x0A',
      //   {
      //     type: 'raw', format: 'html', flavor: 'file',
      //     data: `http://207.180.246.8:8080/print.html?logo=${logo}`,
      //     options: { language: "ESCPOS" } //, "pageWidth": ..., "pageHeight": ... },
      //  },
      //   '\x1B' + '\x45' + '\x0D', // bold on
      //   'Here\'s some bold text!',
      //   '\x1B' + '\x45' + '\x0A', // bold off
      //   '\x0A' + '\x0A',
      //   '\x1B' + '\x61' + '\x32', // right align
      //   '\x1B' + '\x21' + '\x30', // em mode on
      //   'DRINK ME',
      //   '\x1B' + '\x21' + '\x0A' + '\x1B' + '\x45' + '\x0A', // em mode off
      //   '\x0A' + '\x0A',
      //   '\x1B' + '\x61' + '\x30', // left align
      //   '------------------------------------------' + '\x0A',
      //   '\x1B' + '\x4D' + '\x31', // small text
      //   'EAT ME' + '\x0A',
      //   '\x1B' + '\x4D' + '\x30', // normal text
      //   '------------------------------------------' + '\x0A',
      //   'normal text',
      //   '\x1B' + '\x61' + '\x30', // left align
      //   '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A' + '\x0A',
      //   '\x1B' + '\x69',          // cut paper (old syntax)
      //   // '\x1D' + '\x56'  + '\x00' // full cut (new syntax)
      //   // '\x1D' + '\x56'  + '\x30' // full cut (new syntax)
      //   // '\x1D' + '\x56'  + '\x01' // partial cut (new syntax)
      //   // '\x1D' + '\x56'  + '\x31' // partial cut (new syntax)
      //   '\x10' + '\x14' + '\x01' + '\x00' + '\x05',  // Generate Pulse to kick-out cash drawer**
      //   // **for legacy drawer cable CD-005A.  Research before using.
      // ];

      return qz.print(config, data);
    }).catch(function (e) { console.error(e); });
  }

  function findPrinters() {
    qz.printers.find().then(function (data) {
      var list = '';
      for (var i = 0; i < data.length; i++) {
        list += "&nbsp; " + data[i] + "<br/>";
      }
      // displayMessage("<strong>Available printers:</strong><br/>" + list);
      console.log(list, 'lista');
    }).catch(function (e) { console.error(e); });
  }


  return (

    <div>
      <Button style={{ zIndex: 100, paddingTop: '150px' }} onClick={() => findPrinters()}>Mostrar Impresoras</Button>
      <Button style={{ zIndex: 100, paddingTop: '150px' }} onClick={() => imprimir()}>Imprimir</Button>

    </div>

  )
}

export default ComponenPrint  