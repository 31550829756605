import { BACKEND_DOMAIN } from "./api";
import { NO_IMAGE } from "./constants";

export const getUrlImage = (path) => {
    if(path === "" || path === null) {
        return NO_IMAGE;
    }

    return `${BACKEND_DOMAIN}${path}`;
}

export const clearOrderData = () => {
    localStorage.removeItem("salesReceipt");
    localStorage.removeItem("tipoComprobante");
    sessionStorage.removeItem('pedido');
    sessionStorage.removeItem('nombrePedido');
    localStorage.removeItem("tipoPedido");
}