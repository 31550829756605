import { CheckCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { useEffect } from 'react'
import conver from 'conversor-numero-a-letras-es-ar';
import { useHistory } from 'react-router-dom';
import { requestPrint } from '../../authentication/api-print';
import { API } from '../../util/api';

function ModalPagoExitoso({ mostrarExitoso, setMostrarExitoso, setMostrasModal, listFinal, pFinal }) {

  const user = JSON.parse(localStorage.getItem("UserLoggedIn"));
  const nombre = JSON.parse(sessionStorage.getItem("nombrePedido"));
  const purchaseOrderNumber = sessionStorage.getItem("purchaseOrderNumber");
  // console.log(user);
  const info = user.data[0];
  const logo = user.imagenes[0];
  const idEstablecimiento = user.data[0].idEstableciomiento;

  const history = useHistory();

  // Esto se realiza despues de imprimir
  const finish = () => {
    setMostrarExitoso(false);
    setMostrasModal(false);
    sessionStorage.removeItem('pedido');
    sessionStorage.removeItem('nombrePedido');
    history.push('/main');
  }

  useEffect(() => {

    const convertir = require('numero-a-letras');
    let precioLetra = convertir.NumerosALetras(pFinal);

    const f = new Date();

    const dataEnviar = {
      "purchaseOrderNumber": purchaseOrderNumber,
      "idEstablecimiento": idEstablecimiento,
      "nombre": info.Nombre,
      "ruc": info.ruc,
      "razonSocial": info.razonSocial,
      "telefono": info.telefono,
      "logo": logo,
      "direccion": info.Direccion,
      "cliente": nombre.nombreCompleto,
      "DNICliente": nombre.dni,
      "precioFinal": precioLetra,
      "cashier": "MÓDULO KIOSKO 1", // cashier => cajero
      "dateOfIssue": f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear(), // dateOfIssue => fecha de emisión
      "printingDate": f.getDate() + "/" + (f.getMonth() + 1) + "/" + f.getFullYear(), // printingDate => fecha de impresión
      "voucherType": localStorage.getItem("salesReceipt"), // voucherType => tipo de comprobante
      "paymentMethod": "TARJETA (***2269)", // paymentMethod => Método de pago
      "paymentReference": "1547", // paymentReference => referencia de pago
      "amountPaid": pFinal, // amountPaid => importe pagado
      "subtotal": pFinal - (pFinal * 0.18), // subtotal => subtotal
      "IGVamount": pFinal * 0.18, // IGVamount => monto igv
    }

    // console.log(listFinal, 'listFinal');
    // console.log(dataEnviar, 'dataEnviar');

    const dataTienda = encodeURIComponent(JSON.stringify(dataEnviar));
    const myJSONText = encodeURIComponent(JSON.stringify(listFinal));
    const pFinall = encodeURIComponent(pFinal);

    // const API_LOCAL = `http://127.0.0.1:8000/api`;

    const data = [{
      type: 'pixel',
      format: 'pdf',
      flavor: 'file',
      data: `${API}/print-receipt?infoT=${dataTienda}&data=${myJSONText}&precio=${pFinall}`
      // data: `${API_LOCAL}/print-receipt?infoT=${dataTienda}&data=${myJSONText}&precio=${pFinall}`
    }];
    const dataComanda = [{
      type: 'pixel',
      format: 'pdf',
      flavor: 'file',
      data: `${API}/print-receipt-comanda?infoT=${dataTienda}&data=${myJSONText}&precio=${pFinall}`
      // data: `${API_LOCAL}/print-receipt-comanda?infoT=${dataTienda}&data=${myJSONText}&precio=${pFinall}`
    }];

    requestPrint(data, null, finish, true, dataComanda);
  }, []);

  return (
    <Modal visible={mostrarExitoso} onOk={() => setMostrarExitoso(false)} onCancel={() => setMostrarExitoso(false)} footer={[]} >
      <div style={{ textAlign: 'center' }}>
        <CheckCircleOutlined style={{ fontSize: '80px', color: '#A50B70' }} />
        <div style={{ fontSize: '35px' }} >ModalPagoExitoso</div>
      </div>
    </Modal>
  )
}

export default ModalPagoExitoso
