import React from "react";
import { Route, Switch } from "react-router-dom";
// import Categorias from "./categoria";
// import Configuracion from "./configuracion";

import Main from "./main";
import principal from "./principal";
// import Productos from "./Maintenance/productos";
// import Usuario from "./usuario";
import asyncComponent from "../util/asyncComponent";

const App = ({ match }) => {
  const UserLoggedIn = JSON.parse(localStorage.getItem("UserLoggedIn"));
  console.log(`UserLoggedIn`, UserLoggedIn.data[0]);
  return (
    <div>
      {
        UserLoggedIn.data[0].idTipoUsuario === "1" ? (
          <Switch>
            <Route path={`${match.url}main`} component={Main} />
            <Route path={`${match.url}principal`} component={principal} />
            {/* <Route path={`${match.url}categoria`} component={Categorias} />
            <Route path={`${match.url}carritoCompras`} component={CarritoCompras} />
            <Route path={`${match.url}catalogo`} component={Catalogo} />
            <Route path={`${match.url}contacto`} component={Contacto} />
            <Route path={`${match.url}nosotros`} component={Nosotros} />
            <Route path={`${match.url}nuestrasMarcas`} component={NuestrasMarcas} /> */}
          </Switch>
        ) : (
          // : UserLoggedIn.idTipoUsuario === 2 ?
          <Switch>
            <Route
              path={`${match.url}mantenimiento`}
              component={asyncComponent(() => import("./Maintenance"))}
            />

            {/* <Route path={`${match.url}adminProductos`} component={Productos} />
            <Route path={`${match.url}adminConfiguracion`} component={Configuracion} />
            <Route path={`${match.url}adminCategoria`} component={Categorias} />*/}
            {/* <Route path={`${match.url}adminUsuario`} component={Usuario} /> */}
          </Switch>
        )
        //   :
        //   <Switch>

        //     <Route path={`${match.url}mantenimiento`} component={Mantenimiento} />
        //     <Route path={`${match.url}solicitudes`} component={Solicitudes} />
        //     <Route path={`${match.url}dashboard`} component={Dashboard} />

        //   </Switch>
      }
    </div>
  );
};

export default App;
