import { CloseCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import React, { useEffect } from 'react'

function ModalPagoFallido({mostrarFallido, setMostrarFallido, setMostrasModal, setOpenIns}) {

    useEffect(() => {
        const timer = setTimeout(() => {
            setMostrarFallido(false);
            setMostrasModal(false);
            setOpenIns(false);
        }, 2500);
        return () => clearTimeout(timer);
    }, []);


    return (
        <Modal visible={mostrarFallido} onOk={() => setMostrarFallido(false)} onCancel={() => setMostrarFallido(false)} footer={[]} >
            <div style={{textAlign:'center'}}>
            <CloseCircleOutlined style={{fontSize:'80px', color:'#A50B70'}} />
                <div style={{fontSize:'35px'}}>Modal Pago Fallido</div>
                <h2 style={{fontSize:'25px'}}>Vuelva a seleccionar su forma de pago</h2>
            </div>
        </Modal>
    )
}

export default ModalPagoFallido