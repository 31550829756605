import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { useState } from 'react'

function ComponentAdicional({onChange}) {

    const [cantidad, setCantidad] = useState(1);
    const sumar = () => {
        const a = cantidad + 1;
        if(a >= 10){
            setCantidad(9)
        }else{
            setCantidad(a)

        }
        onChange(a)
    }

    const restar = () => {
        const a = cantidad - 1;
        if(a <= 0){
            setCantidad(1)
        }else{
            setCantidad(a)

        }
        onChange(a)
    }

    return (
        <Row style={{paddingTop:'25px'}}>
            <Col span={9} style={{ textAlign: 'end' }}>
                <Button onClick={() => restar()} style={{ width: '60px', height: '60px' }} icon={<MinusOutlined style={{ fontSize: '35px' }} />} ></Button>
            </Col>
            <Col span={2} style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '40px' }}>{cantidad}</h2>
            </Col>
            <Col span={9} style={{ textAlign: 'end' }}>
                <Button onClick={() => sumar()} style={{ backgroundColor: '#A50B70', color: 'white', width: '60px', height: '60px' }} icon={<PlusOutlined style={{ fontSize: '35px' }} />} ></Button>
            </Col>
        </Row>
    )
}

export default ComponentAdicional