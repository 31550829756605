import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";
import {
  ReconciliationOutlined,
  SettingOutlined,
  TagOutlined,
} from "@ant-design/icons";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const nivel = JSON.parse(localStorage.getItem("token_activo"));

  return (
    <>
      {nivel.data[0].idTipoUsuario === "1" ? (
        <div>
          <SidebarLogo
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <div className="gx-sidebar-content">
            <div
              className={`gx-sidebar-notifications ${getNoHeaderClass(
                navStyle
              )}`}
            >
              <UserProfile />
              <AppsNavigation />
            </div>
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
              >
                <Menu.Item key="main">
                  <Link to="/main">
                    <i className="icon icon-crypto" />
                    <span>tienda</span>
                  </Link>
                </Menu.Item>

                {/* <SubMenu key="mantenimiento" popupClassName={getNavStyleSubMenuClass(navStyle)}
                title={<span> <i className="icon icon-dasbhoard" />
                  <span>Mantenimiento</span></span>}>
                <Menu.Item key="/mantenimiento/pymes">
                  <Link to="/mantenimiento/pymes">
                    <i className="icon icon-crypto" />
                    <span>Pymes</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/mantenimiento/usuarios">
                  <Link to="/mantenimiento/usuarios">
                    <i className="icon icon-crypto" />
                    <span>Usuario</span>
                  </Link>
                </Menu.Item>

              </SubMenu>

              <Menu.Item key="solicitudes">
                <Link to="/solicitudes">
                  <i className="icon icon-crypto" />
                  <span>Solicitudes</span>
                </Link>
              </Menu.Item> */}

                {/* <SubMenu key="Solicitudes" popupClassName={getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className="icon icon-dasbhoard"/>
                           <span>Solicitudes</span></span>}>
                             </SubMenu> */}
              </Menu>
            </CustomScrollbars>
          </div>
        </div>
      ) : (
        <>
          <SidebarLogo
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <div className="gx-sidebar-content">
            <div
              className={`gx-sidebar-notifications ${getNoHeaderClass(
                navStyle
              )}`}
            >
              <UserProfile />
              <AppsNavigation />
            </div>
            <CustomScrollbars className="gx-layout-sider-scrollbar">
              <Menu
                defaultOpenKeys={[defaultOpenKeys]}
                selectedKeys={[selectedKeys]}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
              >
                <Menu.Item key="adminProductos">
                  <Link to="/mantenimiento/productos">
                    <TagOutlined style={{ fontSize: "18px" }} />
                    <span>Productos</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="adminConfiguracion">
                  <Link to="/mantenimiento/configuracion">
                    <SettingOutlined style={{ fontSize: "18px" }} />
                    <span>Configuracion </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="adminCategoria">
                  <Link to="/mantenimiento/categoria">
                    <ReconciliationOutlined style={{ fontSize: "18px" }} />
                    <span>Categorias </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="adminBanners">
                  <Link to="/mantenimiento/banners">
                    <ReconciliationOutlined style={{ fontSize: "18px" }} />
                    <span>Banners </span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="adicionales">
                  <Link to="/mantenimiento/adicionales">
                    <SettingOutlined style={{ fontSize: "18px" }} />
                    <span>Servicios adicionales</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="ventas">
                  <Link to="/mantenimiento/ventas">
                    <SettingOutlined style={{ fontSize: "18px" }} />
                    <span>Ventas</span>
                  </Link>
                </Menu.Item>

                {/*<Menu.Item key="adminUsuario">
                  <Link to="/adminUsuario">
                    <ReconciliationOutlined style={{ fontSize: "18px" }} />
                    <span>Usuarios </span>
                  </Link>
                </Menu.Item>*/}

                {/*<SubMenu
                  key="mantenimiento"
                  popupClassName={getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      {" "}
                      <i className="icon icon-dasbhoard" />
                      <span>Mantenimiento</span>
                    </span>
                  }
                >
                  <Menu.Item key="/mantenimiento/pagination">
                    <Link to="/mantenimiento/pagination">
                      <i className="icon icon-crypto" />
                      <span>Paginación</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/mantenimiento/establecimientos">
                    <Link to="/mantenimiento/establecimientos">
                      <i className="icon icon-crypto" />
                      <span>Establecimientos</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="/mantenimiento/usuarios">
                    <Link to="/mantenimiento/usuarios">
                      <i className="icon icon-crypto" />
                      <span>Usuario</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>*/}

                {/* 
              <Menu.Item key="solicitudes">
                <Link to="/solicitudes">
                  <i className="icon icon-crypto" />
                  <span>Solicitudes</span>
                </Link>
              </Menu.Item> */}

                {/* <SubMenu key="Solicitudes" popupClassName={getNavStyleSubMenuClass(navStyle)}
                         title={<span> <i className="icon icon-dasbhoard"/>
                           <span>Solicitudes</span></span>}>
                             </SubMenu> */}
              </Menu>
            </CustomScrollbars>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(SidebarContent);
