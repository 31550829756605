import React from "react";
import { Button, Form, Input } from "antd";
// import {useDispatch, useSelector} from "react-redux";
import { useAuth } from "../authentication";
import AppNotificationContainer from "../components/AppNotificationContainer";

// import {
//   hideMessage,
//   showAuthLoader,
//   userFacebookSignIn,
//   userGithubSignIn,
//   userGoogleSignIn,
//   userSignIn,
//   userTwitterSignIn
// } from "../appRedux/actions";

// import IntlMessages from "util/IntlMessages";
// import { Link } from "react-router-dom";
// import CircularProgress from "../components/CircularProgress";
// import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
// import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
// import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
// import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
// import { values } from "lodash";
// import logo from "../assets/imagenes/logoBlanco.png"

const SignIn = () => {

  const { isLoading, error, userLogin } = useAuth();
  const onFinishFailed = errorInfo => console.log(errorInfo);

  const onFinish = values => {
    // console.log(values);
    const { usuario, clave, codTienda } = values;
    userLogin({
      usuario: usuario,
      clave: clave,
      codTienda: codTienda
    })
  };

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content" style={{ backgroundColor: '#9E187E' }}>

            {/* <div className="gx-app-logo-content-bg">
              <img src={"https://via.placeholder.com/272x395"} alt='Neature'/>
            </div> */}

            <div className="gx-app-logo-wid">
              <h1 style={{ paddingTop: "4rem" }}>Bienvenido!</h1>
              <h2 style={{ color: "white" }}>Somos la mejor plataforma de ventas</h2>
              {/* <p><IntlMessages id="app.userAuth.bySigning"/></p>
              <p><IntlMessages id="app.userAuth.getAccount"/></p> */}
            </div>

            {/* <div className="gx-app-logo">
              <img style={{ with: "50%", height: "70px" }} alt="example" src={logo} />
            </div> */}

          </div>
          <div className="gx-app-login-content">
            <div style={{ width: '100%', padding: '15px' }}>
              <h3
                style={{
                  textAlign: 'center',
                  color: '#04225B',
                  fontSize: "x-large"
                }}>
                Iniciar sesión
              </h3>
            </div>
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <Form.Item
                initialValue="4020"
                // initialValue="4040"
                rules={[{ required: true, message: 'El código de la tienda es necesario' }]} name="codTienda">
                <Input placeholder="CodTienda" />
              </Form.Item>
              <Form.Item
                initialValue="admin"
                rules={[{ required: true, message: 'Ingrese su Usuario por favor' }]} name="usuario">
                <Input placeholder="Usuario" />
              </Form.Item>
              <Form.Item
                initialValue="admin123"
                rules={[{ required: true, message: 'Ingrese la Clave por favor' }]} name="clave">
                <Input type="password" placeholder="Contraseña    " />
              </Form.Item>
              {/* <Form.Item>
                <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                <span className="gx-signup-form-forgot gx-link"><IntlMessages
                  id="appModule.termAndCondition"/></span>
              </Form.Item> */}
              <Form.Item>
                <Button style={{ backgroundColor: "#9E187E", color: 'white' }} htmlType="submit">
                  Ingresar
                </Button>
                {/* <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp" /></Link> */}
              </Form.Item>
              {/* <div className="gx-flex-row gx-justify-content-between">
                <span>or connect with</span>
                <ul className="gx-social-link">
                  <li>
                    <GoogleOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGoogleSignIn());
                    }}/>
                  </li>
                  <li>
                    <FacebookOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userFacebookSignIn());
                    }}/>
                  </li>
                  <li>
                    <GithubOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userGithubSignIn());
                    }}/>
                  </li>
                  <li>
                    <TwitterOutlined onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userTwitterSignIn());
                    }}/>
                  </li>
                </ul>
              </div> */}
              {/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'demo@example.com' and password: 'demo#123'</span> */}
            </Form>
          </div>
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
