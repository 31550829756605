import { Button, Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import Gif from '../../img/procesando.gif';
import ModalPagoExitoso from './ModalPagoExitoso';
import ModalPagoFallido from './ModalPagoFallido';
import { fnNewOrder } from '../../data/sales';

function ModalSimulacion({ mostrasModal, setMostrasModal, setOpenIns, listFinal, pFinal }) {
  const [mostrarFallido, setMostrarFallido] = useState(false);
  const [mostrarExitoso, setMostrarExitoso] = useState(false);
  console.log(`listFinal`, listFinal);

  const pagoExitoso = async () => {
    const tipoDoc = sessionStorage.getItem("tipoComprobante");
    const cliente = JSON.parse(sessionStorage.getItem("datosClientePedido"));
    const establecimiento = JSON.parse(localStorage.getItem("token_activo"))
      .data[0].idEstableciomiento;

    const params = {
      montoCentimos: parseInt(
        parseFloat(parseFloat(pFinal).toFixed(2)) * 100,
        10
      ),
      montoTotal: parseFloat(pFinal).toFixed(2),
      idEstableciomiento: establecimiento,
      listaFinal: listFinal,
      tipoDoc: tipoDoc,
      cliente: cliente,
    };

    console.log(`params`, params);
    const response = await fnNewOrder(params);
    console.log(`response`, response);

    if (!response.data.success)
      throw new Error("No se pudo obtener el número de orden");

    const purchaseOrderNumber = response.data.data
    console.log(`purchaseOrderNumber`, purchaseOrderNumber);
    sessionStorage.setItem("purchaseOrderNumber", purchaseOrderNumber);
    sessionStorage.setItem("orderId", JSON.stringify(purchaseOrderNumber));
    setMostrarExitoso(true);
  }

  const pagoFallido = () => {
    setMostrarFallido(true);
  }


  return (
    <Modal visible={mostrasModal} onOk={() => setMostrasModal(false)} onCancel={() => setMostrasModal(false)} footer={[]} >
      <div style={{ textAlign: 'center' }}>
        <img src={Gif} alt="cargando" />
        <h1>Procesando Pago</h1>
        <Row>
          <Col span={12}>
            <Button onClick={pagoExitoso} >Pago exitoso</Button>
          </Col>
          <Col span={12}>
            <Button onClick={pagoFallido} >Pago Fallido</Button>
          </Col>
        </Row>
        {
          mostrarExitoso ?
            <ModalPagoExitoso mostrarExitoso={mostrarExitoso} setMostrarExitoso={setMostrarExitoso} setMostrasModal={setMostrasModal} listFinal={listFinal} pFinal={pFinal} />
            : null
        }
        {
          mostrarFallido ?
            <ModalPagoFallido mostrarFallido={mostrarFallido} setMostrarFallido={setMostrarFallido} setMostrasModal={setMostrasModal} setOpenIns={setOpenIns} />
            : null
        }
      </div>
    </Modal>
  )
}

export default ModalSimulacion;
