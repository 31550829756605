export const API_KEY = Object.freeze({
  PUBLIC: "pk_test_cf30fbeae5d60d7a",
  SECRET: "sk_test_f7af9b34c5900106",
});

export const CULQI_CHECKOUT = Object.freeze({
  TITLE_STORE: "Culqi Store",
  // LOGO: "https://culqi.com/LogoCulqi.png",
});

export const KIOSKO_CONF = Object.freeze({
  USER_NAME: "quiosco",
  PASSWORD: "123456",
  VARIANT_CODE: "KIOSKUNT1",
});
