import { CloseOutlined } from '@ant-design/icons'
import Drawer from 'react-modern-drawer'
import { Button, Image } from 'antd'
import React, { useState } from 'react'
import instrucciones from '../../img/instrucciones.png';
import Tarjetas from '../../img/tarjetas.png';
import ModalSimulacion from './ModalSimulacion';

function DrawerInstrucciones({ openIns, setOpenIns, pFinal, setOpenPrint, listFinal }) {
    const pedido = JSON.parse(sessionStorage.getItem('pedido'));
    console.log(`pedido`, pedido);
    console.log(`listFinal`, listFinal);

    const [mostrasModal, setMostrasModal] = useState(false);

    const toggleDrawer = () => {
        setOpenIns(false)
    }

    const mostrarModal =()=>{
        console.log('mostral modal');
        setMostrasModal(true);
    }
    return (
        <Drawer
            open={openIns}
            onClose={toggleDrawer}
            direction='bottom'
            // className='bla bla bla'
            style={{ backgroundColor: '#F2F2F2', width: '80%', height: '85%', marginLeft: '10%', borderRadius: '50px 50px 0 0' }}
        >
            <div style={{ width: '100%', padding: '30px', textAlign: 'end' }}>
                <Button onClick={() => setOpenIns(false)} style={{ borderRadius: '20px' }} icon={<CloseOutlined style={{ fontSize: '30px' }} />} ></Button>
            </div>
            <h1 style={{ fontSize: '40px', textAlign: 'center' }}>Total a pagar</h1>
            <h1 style={{ fontSize: '80px', textAlign: 'center', fontWeight: '600', color: '#A50B70' }}>{'S/' + pFinal}</h1>
            <h1 style={{ paddingTop: '20px', textAlign: 'center', fontSize: '50px', fontWeight: '500', color: 'black' }}>Siga las instrucciones de la maquina POS </h1>
            <h1 style={{ paddingTop: '20px', textAlign: 'center', fontSize: '35px', color: 'black' }}>(Utilize su tarjeta de forma segura) </h1>


            <div style={{ textAlign: 'center' }}>
                <img style={{ width: '550px' }} src={instrucciones} alt='' />.

                <div style={{ fontSize: '30px', textAlign: 'center' }}>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a onClick={() => setOpenIns(false)} style={{ color: 'black', paddingTop: '50px' }}>Volver</a>
                </div>
            </div>
            <div style={{ textAlign: 'center', paddingTop: '20px' }}>
                <Button style={{ zIndex:'50', width: '250px', height: "60px", fontSize: '30px' }} onClick={() => mostrarModal()}>Realizar Pago</Button>

            </div>
            <div style={{ position: 'absolute', bottom: '5px' }}>
                <h1 style={{ paddingTop: '100px', textAlign: 'center', fontSize: '35px', color: 'black' }}>Aceptamos  todas las tarjetas  de debito y credito.</h1>
                <img style={{ width: '100%', height: '100px' }} src={Tarjetas} alt='' />
            </div>

            {
                mostrasModal ?
                    <ModalSimulacion mostrasModal={mostrasModal} setMostrasModal={setMostrasModal} setOpenIns={setOpenIns} listFinal={listFinal} pFinal={pFinal} />
                    : null
            }

        </Drawer>
    )
}

export default DrawerInstrucciones
