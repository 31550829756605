import { CloseOutlined } from "@ant-design/icons";
import Drawer from "react-modern-drawer";
import { Button, Input } from "antd";
import React, { useEffect, useState } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./keyboar.css";

function DrawerNombre({
  openNombre,
  setOpenNombre,
  setMostrarComprobante,
}) {

  const [layoutName, setLayoutName] = useState("default");
  const [nombreOrden, setNombreOrden] = useState("");
  const [estadoBtn, setEstadoBtn] = useState(true);


  const toggleDrawer = () => {
    setOpenNombre(false);
  };

  const continuar = () => {
    setMostrarComprobante(true);
    setOpenNombre(false);
  };

  const onChange = (input) => {
    console.log("Input changed", input);
    if (input.length >= 3) {
      setEstadoBtn(false);
    }

    setNombreOrden(input);
    const a = {
      nombre: input,
      nombreCompleto: null,
      dni: null,
      celular: null,
      email: null,
    };

    sessionStorage.setItem("nombrePedido", JSON.stringify(a));
  };

  
  const onKeyPress = (button) => {
    console.log("Button pressed", button);
    /**
     * If you want to handle the shift and caps lock buttons
     */
    // if (button === "{shift}" || button === "{lock}") handleShift();
    // Resto de la lógica de manejo de teclas
  };

  const handleShift = () => {
    setLayoutName(layoutName === "default" ? "shift" : "default");
  };

  const buttonTheme = [
    {
      class: "hg-red",
      buttons:
        "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}     {tab} q w e r t y u i o p [ ] \\  {lock} a s d f g h j k l ; ' {enter}    {shift} z x c v b n m , . / {shift}      .com @ {space}",
    },
    {
      class: "hg-highlight",
      buttons: "Q q",
    },
  ];

  useEffect(() => {
    var data = JSON.parse(sessionStorage.getItem("nombrePedido"));
    console.log(data, "data del nombre");
    handleShift();
    if (data) {
      setNombreOrden(data.nombre);
      setEstadoBtn(false);
    }
    return () => {
      setNombreOrden("");
    }
  }, []);

  return (
    <Drawer
      open={openNombre}
      onClose={toggleDrawer}
      direction="bottom"
      // className='bla bla bla'
      style={{
        width: "95%",
        height: "60%",
        marginLeft: "2.5%",
        borderRadius: "50px 50px 0 0",
      }}
    >
      <div
        style={{
          position: "absolute",
          width: "100%",
          padding: "30px 30px 0 30px",
          textAlign: "end",
        }}
      >
        <Button
          onClick={() => setOpenNombre(false)}
          style={{ borderRadius: "20px" }}
          icon={<CloseOutlined style={{ fontSize: "30px" }} />}
        ></Button>
      </div>
      <h1 style={{ fontSize: "40px", textAlign: "center", marginTop: "30px" }}>
        Esta ORDEN será a nombre de:
      </h1>
      <div
        style={{
          border: "1px solid #C00",
          width: "80%",
          height: "100px",
          margin: "auto",
          borderRadius: "15px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div style={{ textAlign: "center", width: "100%" }}>
          <h1 style={{ fontSize: "40px", fontWeight: "600" }}>
            {nombreOrden}
          </h1>
        </div>
      </div>
      <h1
        style={{
          fontSize: "30px",
          textAlign: "center",
          fontWeight: "400",
          paddingTop: "20px",
          color: "#A8A8A8",
        }}
      >
        {" "}
        Cuando la orden este lista, te llamamos por este nombre.
      </h1>

      <div style={{ padding: "10px" }}>
        <Keyboard
          onChange={onChange}
          onKeyPress={onKeyPress}
          maxLength={25}
          // theme={"hg-theme-default hg-layout-default myTheme"}
          layoutName={layoutName}
          layout={{
            default: [
              "` 1 2 3 4 5 6 7 8 9 0 - = {bksp}",
              "{tab} q w e r t y u i o p [ ] \\",
              "{lock} a s d f g h j k l ; ' {enter}",
              "{shift} z x c v b n m , . / {shift}",
              ".com @ {space}",
            ],
            shift: [
              "~ ! @ # $ % ^ & * ( ) _ + {bksp}",
              "{tab} Q W E R T Y U I O P { } |",
              '{lock} A S D F G H J K L : " {enter}',
              "{shift} Z X C V B N M < > ? {shift}",
              ".com @ {space}",
            ],
          }}
          buttonTheme={buttonTheme}
        />
      </div>

      <div style={{ textAlign: "center", paddingTop: "50px" }}>
        <Button
          disabled={estadoBtn}
          onClick={() => continuar()}
          style={{
            backgroundColor: "#A50B70",
            fontSize: "45px",
            color: "white",
            height: "100px",
            width: "30%",
            borderRadius: "15px",
          }}
        >
          Continuar
        </Button>
      </div>
    </Drawer>
  );
}

export default DrawerNombre;
