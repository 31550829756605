import styled from "styled-components";

export const DivSub = styled.div`
text-align: center;
background-color: ${props => props.active === true ?  props.primary : props.secundary};
border-radius: 10px;
width: 150px;
height: 240px;
padding: 20px 0 0 0;
`;

export const TitleSub = styled.h2`
color: ${props => props.active == true?  props.primary : props.secundary};
padding-top: 20px;
font-size: 20px;
width: 130px  ;
text-align:center;
`;
