import React, { useState } from "react";
// import { Button, Checkbox, Col, DatePicker, Form, Input, Row } from "antd";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import { Link } from "react-router-dom";
import AppNotificationContainer from "../components/AppNotificationContainer";
import { useAuth } from "../authentication";
import { DeleteOutlined } from "@ant-design/icons";
// import {useDispatch, useSelector} from "react-redux";
// import {
//   hideMessage,
//   showAuthLoader,
//   userFacebookSignIn,
//   userGithubSignIn,
//   userGoogleSignIn,
//   userSignUp,
//   userTwitterSignIn
// } from "../appRedux/actions";

import IntlMessages from "util/IntlMessages";
// import {message} from "antd/lib/index";
// import CircularProgress from "../components/CircularProgress";
// import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
// import FacebookOutlined from "@ant-design/icons/lib/icons/FacebookOutlined";
// import GithubOutlined from "@ant-design/icons/lib/icons/GithubOutlined";
// import TwitterOutlined from "@ant-design/icons/lib/icons/TwitterOutlined";
import logo from "../assets/imagenes/logoBlanco.png"
import { getUrlImage } from "../util/functions";

// import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";


const FormItem = Form.Item;

const SignUp = () => {
  const { isLoading, error, userSignup, userSignupPyme } = useAuth();
  // const [imagen, setImagen] = useState({
  //   loading: false,
  // })
  const [imagenURL, setImagenURL] = useState(null);
  const [imagenURLDoc, setImagenURLDoc] = useState(null);


  const [imagen, setImagen] = useState(null);
  const [imagenDoc, setImagenDoc] = useState(null);

  const [hover, setHover] = useState(false);
  const [hoverDoc, setHoverDoc] = useState(false);


  const [formPyme, setFormPyme] = useState(false);

  const onFinishFailed = errorInfo => console.log(errorInfo);
  const onFinishFailedPyme = errorInfo => console.log(errorInfo);
  // const onFinishFailedPyme = errorInfo => console.log(errorInfo);



  const handleFile = event => {
    setImagenURL(URL.createObjectURL(event.target.files[0]));
    setImagen(event.target.files[0]);
    // datosEnviar.imagen_bien = event.target.files[0];
  }


  const handleFileDoc = event => {
    setImagenURLDoc(URL.createObjectURL(event.target.files[0]));
    setImagenDoc(event.target.files[0]);
    // datosEnviar.imagen_bien = event.target.files[0];
  }


  const onFinish = values => {
    const { nombre, apellido, celular, correo, direccion, fechaNacimiento, usuario, clave } = values;
    userSignup({ nombre, apellido, celular, correo, direccion, fechaNacimiento, usuario, clave, imagen });
  };
  const onFinishPyme = values => {
    const { razonSocial, ruc, direccion, descripcion,celular, usuario, clave } = values;
    userSignupPyme({ razonSocial, ruc, direccion, descripcion,celular, usuario, clave, imagen, imagenDoc});
  };

  function changeBackgroundHover(e) {
    e.target.style.background = 'rgba(0,0,0,0.3)';
    setHover(true);
  }

  function changeBackgroundLeave(e) {
    e.target.style.background = 'rgba(255,255,255,0)';
    setHover(false);
  }
 

  function changeBackgroundHoverDoc(e) {
    e.target.style.background = 'rgba(0,0,0,0.3)';
    setHoverDoc(true);
  }

  function changeBackgroundLeaveDoc(e) {
    e.target.style.background = 'rgba(255,255,255,0)';
    setHoverDoc(false);
  }
  const hiddenFileInput = React.useRef(null);
  const hiddenFileInputDoc = React.useRef(null);


  const dateFormat = 'YYYY/MM/DD';

  const mostrarForm = () => {

    formPyme ?
      setFormPyme(false)
      :
      setFormPyme(true)

  }

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              {/* <img src={"https://via.placeholder.com/272x395"} alt='Neature'/> */}
            </div>
            <div className="gx-app-logo-wid">
              <h1>Registrate </h1>
              <p>Forma parte de esta gran comunidad y trabaja con nosotros</p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={logo} />
            </div>
          </div>

          <div className="gx-app-login-content">
            <Button onClick={() => mostrarForm()}>{formPyme ? ' <- registrarme como cliente' : 'Registrar Pyme -> '}</Button>
            {
              formPyme ?
                <Form
                  initialValues={{ remember: true }}
                  name="basic"
                  onFinish={onFinishPyme}
                  onFinishFailed={onFinishFailedPyme}
                  className="gx-signin-form gx-form-row0">
                  <Row style={{ flexDirection: "row", paddingTop: '20px' }}>

                    <Col lg={8} md={8} sm={8} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                      {imagenURL == null ?
                        <>
                          <input
                            style={{ display: 'none' }}
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFile}
                            ref={hiddenFileInput}
                          />
                          <Button
                            style={{ height: '100px', width: '100px', whiteSpace: 'break-spaces', textAlign: 'center', lineHeight: '20px' }}
                            onClick={() => hiddenFileInput.current.click()}
                          >
                            Subir imagen
                          </Button>
                        </>
                        :
                        <div style={{ height: "100px", width: "100px", position: 'relative' }} onClick={() => { setImagenURL(null) }}>

                          <div
                            style={{
                              position: 'absolute',
                              top: '-2px',
                              bottom: '-2px',
                              left: '-2px',
                              right: '-2px',
                              margin: 'auto',
                              width: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center', textAlign: 'center'
                            }}
                            onMouseOver={changeBackgroundHover}
                            onMouseLeave={changeBackgroundLeave}
                          >
                            {hover ?
                              <DeleteOutlined style={{ color: 'white', fontSize: '20px', cursor: 'pointer', padding: '10px', borderRadius: '20px' }} />
                              :
                              null
                            }
                          </div>
                          <img src={getUrlImage(imagenURL)} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="" />
                        </div>
                      }
                    </Col>

                    <Col lg={16} md={16} sm={16} xs={24} >
                      <FormItem rules={[{ required: true, message: 'Por favor ingrese un nombre!' }]} name="razonSocial">
                        <Input placeholder="Razon Social" />
                      </FormItem>
                      <FormItem rules={[{ required: true, message: 'Por favor ingrese sus apellidos!' }]} name="ruc">
                        <Input placeholder="Ruc" type={"number"} />
                      </FormItem>

                    </Col>



                  </Row>


                  <FormItem name="direccion">
                    <Input placeholder="Direccion" />
                  </FormItem>
                  <FormItem name="descripcion">
                    <Input placeholder="Descripcion" />
                  </FormItem>
                  <FormItem name="celular">
                    <Input placeholder="WhatsApp" type={"number"} />
                  </FormItem>

                  {/* <FormItem name="correo" rules={[{ required: true, type: 'email', message: 'Por favor ingrese un correo valido!' }]}>
                <Input placeholder="Correo" />
              </FormItem> */}

                  <FormItem name="usuario">
                    <Input placeholder="Usuario" />
                  </FormItem>

                  <FormItem name="clave"
                    rules={[{ required: true, message: 'Porfavor ingrese una contraseña!' }]}>
                    <Input type="password" placeholder="Clave" />
                  </FormItem>
                  <Col lg={24} md={24} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                    subir Ficha RUC
                    </Col>

                  <Col lg={24} md={24} sm={24} xs={24} style={{ display: 'flex', alignItems: 'center', paddingTop:"20px" }}>
                
                    {imagenURLDoc == null ?
                      <>
                        <input
                          style={{ display: 'none' }}
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleFileDoc}
                          ref={hiddenFileInputDoc}
                        />
                        <Button
                          style={{ height: '100px', width: '100px', whiteSpace: 'break-spaces', textAlign: 'center', lineHeight: '20px' }}
                          onClick={() => hiddenFileInputDoc.current.click()}
                        >
                          Subir imagen
                        </Button>
                      </>
                      :
                      <div style={{ height: "100px", width: "100px", position: 'relative' }} onClick={() => { setImagenURLDoc(null) }}>

                        <div
                          style={{
                            position: 'absolute',
                            top: '-2px',
                            bottom: '-2px',
                            left: '-2px',
                            right: '-2px',
                            margin: 'auto',
                            width: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center', textAlign: 'center'
                          }}
                          onMouseOver={changeBackgroundHoverDoc}
                          onMouseLeave={changeBackgroundLeaveDoc}
                        >
                          {hoverDoc ?
                            <DeleteOutlined style={{ color: 'white', fontSize: '20px', cursor: 'pointer', padding: '10px', borderRadius: '20px' }} />
                            :
                            null
                          }
                        </div>
                        <img src={getUrlImage(imagenURLDoc)} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="" />
                      </div>
                    }
                  </Col>

                  <FormItem>
                    <Button type="primary" className="gx-mb-0" htmlType="submit"  >
                      <IntlMessages id="app.userAuth.signUp" />
                    </Button>
                    <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signin"><IntlMessages
                      id="app.userAuth.signIn" /></Link>
                  </FormItem>


                </Form>
                :
                <Form
                  initialValues={{ remember: true }}
                  name="basic"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  className="gx-signin-form gx-form-row0">
                  <Row style={{ flexDirection: "row", paddingTop: '20px' }}>

                    <Col lg={8} md={8} sm={8} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                      {imagenURL == null ?
                        <div>
                          <input
                            style={{ display: 'none' }}
                            type="file"
                            accept=".jpg,.jpeg,.png"
                            onChange={handleFile}
                            ref={hiddenFileInput}
                          />
                          <Button
                            style={{ height: '100px', width: '100px', whiteSpace: 'break-spaces', textAlign: 'center', lineHeight: '20px' }}
                            onClick={() => hiddenFileInput.current.click()}
                          >
                            Subir imagen
                          </Button>
                        </div>
                        :
                        <div style={{ height: "100px", width: "100px", position: 'relative' }} onClick={() => { setImagenURL(null) }}>

                          <div
                            style={{
                              position: 'absolute',
                              top: '-2px',
                              bottom: '-2px',
                              left: '-2px',
                              right: '-2px',
                              margin: 'auto',
                              width: 'auto',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center', textAlign: 'center'
                            }}
                            onMouseOver={changeBackgroundHover}
                            onMouseLeave={changeBackgroundLeave}
                          >
                            {hover ?
                              <DeleteOutlined style={{ color: 'white', fontSize: '20px', cursor: 'pointer', padding: '10px', borderRadius: '20px' }} />
                              :
                              null
                            }
                          </div>
                          <img src={getUrlImage(imagenURL)} style={{ width: "100%", height: "100%", objectFit: "contain" }} alt="" />
                        </div>
                      }
                    </Col>

                    <Col lg={16} md={16} sm={16} xs={24} >
                      <FormItem rules={[{ required: true, message: 'Por favor ingrese un nombre!' }]} name="nombre">
                        <Input placeholder="Nombre" />
                      </FormItem>
                      <FormItem rules={[{ required: true, message: 'Por favor ingrese sus apellidos!' }]} name="apellido">
                        <Input placeholder="Apellido" />
                      </FormItem>

                    </Col>



                  </Row>

                  <FormItem rules={[{ required: true, message: 'Por favor ingrese un numero telefonico!' }]} name="celular">
                    <Input placeholder="Celular" />
                  </FormItem>
                  <FormItem name="direccion">
                    <Input placeholder="Direccion" />
                  </FormItem>

                  <FormItem name="correo" rules={[{ required: true, type: 'email', message: 'Por favor ingrese un correo valido!' }]}>
                    <Input placeholder="Correo" />
                  </FormItem>
                  <Form.Item
                    name="fechaNacimiento"
                    label="fecha Nacimiento"
                  >
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                  <FormItem name="usuario">
                    <Input placeholder="Usuario" />
                  </FormItem>

                  <FormItem name="clave"
                    rules={[{ required: true, message: 'Porvamo ingrese una contraseña!' }]}>
                    <Input type="password" placeholder="Password" />
                  </FormItem>
                  {/* <FormItem name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
                <Link className="gx-login-form-forgot" to="/custom-views/user-auth/forgot-password">Forgot
                  password</Link>
              </FormItem> */}
                  <FormItem>
                    <Button type="primary" className="gx-mb-0" htmlType="submit"  >
                      <IntlMessages id="app.userAuth.signUp" />
                    </Button>
                    <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signin"><IntlMessages
                      id="app.userAuth.signIn" /></Link>
                  </FormItem>

                </Form>

            }
            {/* <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0">
              <Row style={{ flexDirection: "row", paddingTop: '20px' }}>

                <Col lg={8} md={8} sm={8} xs={24} style={{ display: 'flex', alignItems: 'center' }}>
                  {imagenURL == null ?
                    <>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        accept=".jpg,.jpeg,.png"
                        onChange={handleFile}
                        ref={hiddenFileInput}
                      />
                      <Button
                        style={{ height: '100px', width: '100px', whiteSpace: 'break-spaces', textAlign: 'center', lineHeight: '20px' }}
                        onClick={() => hiddenFileInput.current.click()}
                      >
                        Subir imagen
                      </Button>
                    </>
                    :
                    <div style={{ height: "100px", width: "100px", position: 'relative' }} onClick={() => { setImagenURL(null) }}>

                      <div
                        style={{
                          position: 'absolute',
                          top: '-2px',
                          bottom: '-2px',
                          left: '-2px',
                          right: '-2px',
                          margin: 'auto',
                          width: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center', textAlign: 'center'
                        }}
                        onMouseOver={changeBackgroundHover}
                        onMouseLeave={changeBackgroundLeave}
                      >
                        {hover ?
                          <DeleteOutlined style={{ color: 'white', fontSize: '20px', cursor: 'pointer', padding: '10px', borderRadius: '20px' }} />
                          :
                          null
                        }
                      </div>
                      <img src={`${imagenURL}`} style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                    </div>
                  }
                </Col>

                <Col lg={16} md={16} sm={16} xs={24} >
                  <FormItem rules={[{ required: true, message: 'Por favor ingrese un nombre!' }]} name="nombre">
                    <Input placeholder="Nombre" />
                  </FormItem>
                  <FormItem rules={[{ required: true, message: 'Por favor ingrese sus apellidos!' }]} name="apellido">
                    <Input placeholder="Apellido" />
                  </FormItem>

                </Col>



              </Row>

              <FormItem rules={[{ required: true, message: 'Por favor ingrese un numero telefonico!' }]} name="celular">
                <Input placeholder="Celular" />
              </FormItem>
              <FormItem name="direccion">
                <Input placeholder="Direccion" />
              </FormItem>

              <FormItem name="correo" rules={[{ required: true, type: 'email', message: 'Por favor ingrese un correo valido!' }]}>
                <Input placeholder="Correo" />
              </FormItem>
              <Form.Item
                name="fechaNacimiento"
                label="fecha Nacimiento"
              >
                <DatePicker format={dateFormat} />
              </Form.Item>
              <FormItem name="usuario">
                <Input placeholder="Usuario" />
              </FormItem>

              <FormItem name="clave"
                rules={[{ required: true, message: 'Porvamo ingrese una contraseña!' }]}>
                <Input type="password" placeholder="Password" />
              </FormItem>
              <FormItem name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
                <Link className="gx-login-form-forgot" to="/custom-views/user-auth/forgot-password">Forgot
                  password</Link>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit"  >
                  <IntlMessages id="app.userAuth.signUp" />
                </Button>
                <span><IntlMessages id="app.userAuth.or" /></span> <Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn" /></Link>
              </FormItem>
          
            </Form> */}
          </div>
          <AppNotificationContainer loading={isLoading} error={error} />
        </div>
      </div>
    </div>
  );
};


export default SignUp;
