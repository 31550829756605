import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import {
  CloseOutlined,
  CloseSquareOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { Button, Input, Space, Popconfirm, Popover, Alert } from "antd";
import { NumericKeyboard } from "react-numeric-keyboard";
import axios from "axios";
import {
  API_MIGO_DNI,
  API_MIGO_RUC,
  API_MIGO_TOKEN,
  APP_MODE,
} from "../../util/constants";
import ModalSendOrderToCheckout from "./ModalSendOrderToCheckout";

function DrawerVoucher({
  open,
  setOpen,
  setPagar,
  setMostrarComprobante,
  listFinal,
  pFinal,
}) {
  const token = API_MIGO_TOKEN;
  const INVOICE = "FACTURA ELECTRÓNICA";

  const salesReceipt = localStorage.getItem("salesReceipt");
  console.log(`salesReceipt`, salesReceipt);
  // es factura?
  const isInvoice = salesReceipt === INVOICE ? true : false;
  const API_MIGO = isInvoice ? API_MIGO_RUC : API_MIGO_DNI;
  // Documento de identidad
  const IdentificationDocument = isInvoice ? "RUC" : "DNI";
  const maxLengthIDNumber = isInvoice ? 11 : 8;
  console.log(`isInvoice`, isInvoice);

  const [errorMigo, setErrorMigo] = useState(null);

  const [closeNumericKeyboard, setCloseNumericKeyboard] = useState(false);
  const [widthOutIDnumber, setWidthOutIDnumber] = useState(true);
  const [heightDrawer, setHeightDrawer] = useState("60%");

  // VARIABLES PARA BOLETA Y PARA FACTURA //
  // Número de DNI o RUC
  const [IDnumber, setIDnumber] = useState("");
  // Nombre completo o Razón social
  const [fullNameOrBusinessName, setFullNameOrBusinessName] = useState("");
  // Direccion es solo para RUC
  const [direccion, setDireccion] = useState("");
  // Enviar pedido a caja
  const [sendOrderToCheckout, setSendOrderToCheckout] = useState(false);

  const onClickWidthOutIDnumber = () => {
    if (widthOutIDnumber) {
      setHeightDrawer("80%");
      setCloseNumericKeyboard(true);
    } else {
      setHeightDrawer("60%");
      setCloseNumericKeyboard(false);
    }
    setWidthOutIDnumber(!widthOutIDnumber);
  };

  const continuar = () => {
    setPagar(true);

    let dataNombre = JSON.parse(sessionStorage.getItem("nombrePedido"));
    console.log(`dataNombre`, dataNombre);
    let identification_type = isInvoice ? 6 : 1;

    if (widthOutIDnumber) {
      console.log("isInvoice === false  && widthDni === false");
      identification_type = 1;
      sessionStorage.setItem("tipoComprobante", 3);
      localStorage.setItem("tipoComprobante", 3);
      setErrorMigo(null);
      setFullNameOrBusinessName("CLIENTES VARIOS");
      setIDnumber("00000000");
      const a = {
        nombre: dataNombre.nombre,
        nombreCompleto: "CLIENTES VARIOS",
        dni: "00000000",
        celular: "",
        email: "",
      };
      sessionStorage.setItem("nombrePedido", JSON.stringify(a));
    }

    const dataCliente = {
      nombre: dataNombre.nombre,
      nombreCompleto: fullNameOrBusinessName,
      identification_type: identification_type,
      identification_number: IDnumber,
      dni: IDnumber,
      celular: "",
      email: "",
    };

    sessionStorage.setItem("datosClientePedido", JSON.stringify(dataCliente));

    if (APP_MODE === "SALES_MODE") {
      setMostrarComprobante(false);
    } else {
      // Enviar pedido a caja
      setSendOrderToCheckout(true);
    }
  };

  let dataNombre = JSON.parse(sessionStorage.getItem("nombrePedido"));
  console.log(`dataNombre`, dataNombre);

  useEffect(() => {
    if (IDnumber.length === maxLengthIDNumber) {
      setCloseNumericKeyboard(false);
      let params = isInvoice
        ? { ruc: IDnumber, token }
        : { dni: IDnumber, token };
      axios
        .post(API_MIGO, params)
        .then((response) => {
          setErrorMigo(null);
          setFullNameOrBusinessName(
            isInvoice
              ? response.data.nombre_o_razon_social
              : response.data.nombre
          );
          setDireccion(isInvoice ? response.data.direccion_simple : "");
          const dataCliente = {
            nombre: dataNombre.nombre,
            nombreCompleto: isInvoice
              ? response.data.nombre_o_razon_social
              : response.data.nombre,
            dni: IDnumber,
            celular: "",
            email: "",
          };
          sessionStorage.setItem("nombrePedido", JSON.stringify(dataCliente));
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            if (error.response.status === 404) {
              setErrorMigo({
                type: "warning",
                msj: `${IdentificationDocument} no encontrado`,
              });
              console.log(error.response.data.message);
              console.log(error.response.statusText); // "Not Found"
            }
          }
        });
    }
  }, [IDnumber]);

  const onClickIdentificationDocument = () => {
    setHeightDrawer("80%");
    setCloseNumericKeyboard(true);
  };

  const onChangeNumericKeyboard = ({ value }) => {
    if (value.length <= maxLengthIDNumber) {
      console.log(`value`, value);
      setIDnumber(value);
    } else {
      setCloseNumericKeyboard(false);
    }
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      direction="bottom"
      style={{
        width: "90%",
        height: heightDrawer,
        marginLeft: "5%",
        borderRadius: "50px 50px 0 0",
      }}
    >
      <div style={{ width: "100%", padding: "30px", textAlign: "end" }}>
        <Button
          onClick={() => setOpen(false)}
          style={{ borderRadius: "20px" }}
          icon={<CloseOutlined style={{ fontSize: "30px" }} />}
        ></Button>
      </div>

      <div>
        <h1 style={{ fontSize: "40px", textAlign: "center" }}>
          ({dataNombre !== null ? dataNombre.nombre : ""}) DATOS PARA TU '
          {salesReceipt}'
        </h1>

        {errorMigo !== null && IDnumber.length === maxLengthIDNumber && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Space direction="vertical" style={{ width: "60%" }}>
              <Alert
                message={errorMigo.msj}
                type={errorMigo.type}
                showIcon
                style={{ fontSize: "35px" }}
              />
            </Space>
          </div>
        )}

        {isInvoice && widthOutIDnumber === false && (
          <h1
            style={{
              fontSize: "30px",
              textAlign: "center",
              fontWeight: "400",
              paddingTop: "50px",
              color: "#A8A8A8",
            }}
          >
            Completa la informacion requerida
          </h1>
        )}
        <div style={{ textAlign: "center" }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <NumericKeyboard
                  style={{ display: "block", position: "inherit" }}
                  isOpen={closeNumericKeyboard}
                  onChange={onChangeNumericKeyboard}
                  mode="simple"
                  leftIcon={
                    <CloseSquareOutlined
                      onClick={() => setCloseNumericKeyboard(false)}
                      style={{ fontSize: "50px" }}
                    />
                  }
                  backSpaceIcon={
                    <ImportOutlined style={{ fontSize: "50px" }} />
                  }
                />
              </div>
            </div>

            {widthOutIDnumber === false && (
              <div>
                <Input
                  onClick={onClickIdentificationDocument}
                  value={IDnumber}
                  style={{
                    width: "60%",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                  placeholder={IdentificationDocument}
                />
                <Input
                  value={fullNameOrBusinessName}
                  style={{
                    width: "60%",
                    fontSize: "30px",
                    textAlign: "center",
                  }}
                  placeholder={
                    isInvoice ? "Razón social" : "Nombres y Apellidos"
                  }
                />
                {isInvoice && (
                  <Input
                    value={direccion}
                    style={{
                      width: "60%",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                    placeholder="Direccion del domicilio fiscal"
                  />
                )}
              </div>
            )}
          </Space>
        </div>

        <div style={{ textAlign: "center", paddingTop: "30px" }}>
          <Button
            onClick={() => onClickWidthOutIDnumber()}
            style={{
              backgroundColor: "#fff",
              fontSize: "45px",
              color: "#000",
              height: "100px",
              borderRadius: "15px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              width: "500px",
            }}
          >
            {widthOutIDnumber
              ? `Agregar ${IdentificationDocument}`
              : `Omitir ${IdentificationDocument}`}
          </Button>
        </div>

        <div style={{ textAlign: "center", paddingTop: "30px" }}>
          <Button
            onClick={() => continuar()}
            style={{
              backgroundColor: "#A50B70",
              fontSize: "45px",
              color: "white",
              height: "100px",
              borderRadius: "15px",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              width: "500px",
            }}
          >
            {APP_MODE === "SALES_MODE" ? "Continuar" : "Enviar pedido a caja"}
          </Button>
        </div>
      </div>
      {sendOrderToCheckout && (
        <ModalSendOrderToCheckout listFinal={listFinal} pFinal={pFinal} />
      )}
    </Drawer>
  );
}

export default DrawerVoucher;
