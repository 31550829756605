import React, { useState } from 'react'
import { Row, Col } from 'antd';
import Consumo from "../../img/consumo.png";
import Llevar from "../../img/llevar.png";
// import DrawerDni from './DrawerDni';
// import DrawerParaLlevar from './DrawerParaLlevar';
// import { useHistory } from 'react-router-dom';
function TipoPedido({ setOpen, setOpenNombre }) {

  const nivel = JSON.parse(localStorage.getItem("token_activo"));

  // Consumir aquí
  const consumeHere = () => {
    console.log(`consumeHere`);
    localStorage.setItem("tipoPedido", "consumeHere");
    setOpen(false);
    setOpenNombre(true);
  }
  // Para llevar
  const toGo = () => {
    console.log(`toGo`);
    localStorage.setItem("tipoPedido", "toGo");
    setOpen(false);
    setOpenNombre(true);
  }

  return (
    <div style={{ position: 'absolute', width: '100%', height: "100%" }}>
      <div style={{ height: '50vh', position: 'relative' }}>
        <div style={{ textAlign: 'center', width: '100%', position: 'absolute', bottom: '10%' }}>
          <img src={nivel.imagenes[0].urlImagen} style={{ width: '400px', height: '400px', borderRadius: '50%' }} alt='' />
          <h1 style={{ fontSize: '70px', fontWeight: '600', paddingTop: '150px' }}>Hola!</h1>
          <h1 style={{ fontSize: '70px', padding: '0', margin: '0' }}>¿Donde vas a consumir</h1>
          <h1 style={{ fontSize: '70px', padding: '0', margin: '0' }}>tu pedido hoy?</h1>
        </div>
      </div>
      <div style={{ height: '50vh', backgroundColor: '#A50B70' }} >
        <Row style={{ width: '100%', marginLeft: '10%', display: 'flex', paddingTop: '23%' }}>
          <Col style={{ backgroundColor: 'white', margin: '20px 60px 20px 20px', padding: '20px', borderRadius: '10%', textAlign: 'center' }} >
            <div onClick={() => consumeHere()}>
              <img src={Consumo} style={{ width: '300px', height: '300px' }} alt='' />
              <h1 style={{ fontSize: '35px', fontWeight: '400' }}>Para consumo</h1>
              <h1 style={{ fontSize: '25px', fontWeight: '400', color: '#A59794' }}>en mesa</h1>
            </div>
          </Col>
          <Col style={{ backgroundColor: 'white', margin: '20px 20px 20px 60px', padding: '20px', borderRadius: '15%', textAlign: 'center' }}  >
            <div onClick={() => toGo()}>
              <img src={Llevar} style={{ width: '300px', height: '300px' }} alt='' />
              <h1 style={{ fontSize: '35px', fontWeight: '400' }} >Para llevar</h1>
              <h1 style={{ fontSize: '25px', fontWeight: '400', color: '#A59794' }}>empaquetado</h1>
            </div>

          </Col>
        </Row>
      </div>

    </div>
  )
}

export default TipoPedido
