import axios from "axios";

// API DOMAIN
let API_DOMAIN = '127.0.0.1:8000'; // 127.0.0.1:8000
let APIwithSSL = false;

// MY DOMAIN FRONTEND
let DOMAIN_BASE = document.domain;
let URLwithSSL = false;

// console.log('DOMAIN_BASE => ', DOMAIN_BASE);
DOMAIN_BASE = DOMAIN_BASE === 'localhost' ? `${DOMAIN_BASE}:3000` : DOMAIN_BASE;
console.log('DOMAIN_BASE => ', DOMAIN_BASE);

if (DOMAIN_BASE === 'quiosco-dev.pagaltoque.com') {
  API_DOMAIN = 'backend-dev.pagaltoque.com';
  APIwithSSL = true;
  URLwithSSL = true;
}

if (DOMAIN_BASE === 'quiosco.pagaltoque.com') {
  API_DOMAIN = 'backend.pagaltoque.com';
  APIwithSSL = true;
  URLwithSSL = true;
}

export const BACKEND_DOMAIN = APIwithSSL ? `https://${API_DOMAIN}` : `http://${API_DOMAIN}`;
export const API = APIwithSSL ? `https://${API_DOMAIN}/api` : `http://${API_DOMAIN}/api`;
export const URL_BASE = URLwithSSL ? `https://${DOMAIN_BASE}` : `http://${DOMAIN_BASE}`;

console.log(`API: ${API}`);
console.log(`URL_BASE: ${URL_BASE}`);

// const authorisation = localStorage.getItem('authorisation') ?? {};

export const httpClient = axios.create({
  baseURL: API,
  // baseURL: `http://localhost/back-kiosko/public/api/`,
  headers: {
    'Content-Type': 'application/json',
    // Authorization: `Bearer ${token}`
  },
});

// delete httpClient.defaults.headers.common.Authorization;
// if (Object.values(authorisation).length > 0) {
//     const { token, type } = JSON.parse(authorisation);
//     // axiosServices.defaults.headers.common.Authorization = `Bearer ${token}`;
//     httpClient.defaults.headers.common.Authorization = `${type} ${token}`;
// }
// // interceptor for http
// httpClient.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
// );
// export default httpClient;

export const clienteKiosko = axios.create({
  baseURL: `https://staging2supermercadosmia.siansystem.com/`,
  headers: {
    "Content-Type": "application/json",
  },
});

export const clienteTipoCambio = axios.create({
  baseURL: `http://api.apis.net.pe/v1/`,
  headers: {
    "Content-Type":
      "application/x-www-form-urlencoded; charset=UTF-8;application/json",
  },
});

export const urlFoto = "http://127.0.0.1:8000/imagenes/";

// API_PRINT: API de impresion
export const API_PRINT = axios.create({
  // baseURL: `https://testsupermercadosmia.siansystem.com/admin/apiPos/signin`,
  baseURL: `https://testsupermercadosmia.siansystem.com/admin/apiPos`,
  headers: {
    'Content-Type': 'application/json',
  },
});
