import React, { useEffect } from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import "./piePagina.css";
import BelowHeader from "../Topbar/BelowHeader/index";
// import Topbar from "../Topbar/index";
// import { footerText } from "../../util/config";
// import logo from "../../assets/imagenes/logoBlanco.png"
import App from "../../routes/index";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  // NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
  // NAV_STYLE_MINI_SIDEBAR,
  // NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  // NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
// import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import AppSidebar from "./AppSidebar";
// import { FacebookOutlined, InstagramOutlined, TwitterOutlined, WhatsAppOutlined } from "@ant-design/icons";

// const { Content, Footer } = Layout;
const { Content } = Layout;

const getContainerClass = (navStyle) => {
  console.log(navStyle, 'mainApp');
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return '';
  }
};

const getNavStyles = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return <HorizontalDefault />;
    case NAV_STYLE_DARK_HORIZONTAL:
      console.log('getNavStyles: HorizontalDark');
      return <HorizontalDark />;
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return <InsideHeader />;
    case NAV_STYLE_ABOVE_HEADER:
      return <AboveHeader />;
    case NAV_STYLE_BELOW_HEADER:
      return <BelowHeader />;
    case NAV_STYLE_FIXED:
    //   return <Topbar />;
    // case NAV_STYLE_DRAWER:
    //   return <Topbar />;
    // case NAV_STYLE_MINI_SIDEBAR:
    //   return <Topbar />;
    // case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
    //   return <NoHeaderNotification />;
    // case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
    //   return <NoHeaderNotification />;
    // eslint-disable-next-line no-fallthrough
    default:
      return null;
  }
};

const MainApp = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  console.log(navStyle, 'importante');
  // console.log('footerss');
  const match = useRouteMatch();
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    })
  }, [dispatch]);

  const nivel = JSON.parse(localStorage.getItem("token_activo"));

  return (
    <Layout className="gx-app-layout">
      {
        nivel.data[0].idTipoUsuario != 1 ?
          <AppSidebar navStyle={navStyle} />
          : null
      }

      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          <App match={match} />


          {/* <Footer>
                <div className="gx-layout-footer-content">
                  {footerText}
                </div>
              </Footer>
               */}
          {/* <Footer className="cont-footer">
                <div>
                  <div className="part1 ">
                    <h3 >QUIENES SOMOS</h3>
                    <h3>TERMINO Y CONDICIONES</h3>
                    <h3>LIBRO DE RECLAMOS</h3>
                  </div>
                  <div className="part2 ">
                    <img src={logo} alt='cargando' />
                    <div>
                      <WhatsAppOutlined style={{ fontSize: '26px', color: '#08c', width: "40px" }} />
                      <TwitterOutlined style={{ fontSize: '26px', color: '#08c', width: "40px" }} />
                      <InstagramOutlined style={{ fontSize: '26px', color: '#08c', width: "40px" }} />
                      <FacebookOutlined style={{ fontSize: '26px', color: '#08c', width: "40px" }} />
                    </div>
                  </div>
                  <div className="part3 ">
                    <h3>CONTACTANOS</h3>
                    <h4 style={{ color: '#08c' }}>INFOO@ICHIVA.COM</h4>
                    <h3>ATENCION AL CLIENTE</h3>
                    <h4 style={{ color: '#08c' }}>924 796 106</h4>
                    <h4 style={{ color: '#08c' }}>987 654 3216</h4>
                  </div>
                </div>
              </Footer> */}


        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

