import Drawer from "react-modern-drawer";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Radio, Row, Tabs, notification } from "antd";
import { CloseOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import "./producto.css";
import ComponentAdicional from "./ComponentAdicional";
import ScrollContainer from "react-indiana-drag-scroll";
import { forEach } from "lodash";
import { getUrlImage } from "../../util/functions";
function ModalProducto(props) {
  const {
    detalleProducto,
    listFinal,
    isOpen,
    setIsOpen,
    dataProducto,
    setCantidad,
    cantidad,
    listOpcionAdi,
    setListOpcionAdi,
    listOpcionMult,
    setListOpcionMult,
    listOpcionUnica,
    setListOpcionUnica,
  } = props;

  console.log(`dataProducto`, dataProducto);
  console.log(`detalleProducto`, detalleProducto);

  const [listCantidad, setListCantidad] = useState({});
  const [precio, setPrecio] = useState(dataProducto.precio);
  const [selectMul, setSelectMul] = useState([]);
  const [mostrarPersonalizar, setMostrarPersonalizar] = useState(
    dataProducto.idTipoProducto == 2 ? false : true
  );
  const { TabPane } = Tabs;
  const toggleDrawer = () => {
    setIsOpen(false);
  };

  useEffect(() => {}, []);

  const sumar = () => {
    const a = cantidad + 1;
    setCantidad(a);
    if (a >= 10) {
      setCantidad(9);
    } else {
      setCantidad(a);
      setPrecio(dataProducto.precio * a);
    }
  };

  const restar = () => {
    const a = cantidad - 1;
    if (a <= 0) {
      setCantidad(1);
    } else {
      setCantidad(a);
      setPrecio(dataProducto.precio * a);
    }
  };

  const personalizar = () => {
    setMostrarPersonalizar(true);
  };
  const handleChange = (e) => {
    console.log(e.target.value);
    // doSomething(e.target.value)
  };
  const agregar = () => {
    console.log(`agregar => dataProducto`, dataProducto);
    sessionStorage.setItem("pedido", JSON.stringify(dataProducto));
    setMostrarPersonalizar(false);
    setIsOpen(false);
    addListFinal();
  };

  const addUnico = (element, id, tit) => {
    // setListOpcionUnica()
    listOpcionUnica[id] = { titulo: tit, cuerpo: element.target.value };
    setListOpcionUnica({ ...listOpcionUnica });
  };

  const addMultiple = (element, id, tit, cant, index) => {
    // setListOpcionUnica()
    console.log(index, "elemenetos");
    console.log(listOpcionMult, " muy importante");

    if (cant == null) {
      setSelectMul(element);
      listOpcionMult[id] = { titulo: tit, cuerpo: element };
      setListOpcionMult({ ...listOpcionMult });
    } else {
      if (selectMul.length + 1 <= cant) {
        setSelectMul(element);
        listOpcionMult[id] = { titulo: tit, cuerpo: element };
        setListOpcionMult({ ...listOpcionMult });
      } else {
        notification.info({
          message: `solo puedes seleccionar ${cant} opciones`,
          description: "",
        });
        if (element.includes(element[element.length - 1])) {
          setSelectMul(
            selectMul.filter((option) => option !== element[element.length - 1])
          );
        } else {
          console.log("nosseeee");
        }
      }
    }
  };

  const addAdi = (element, id) => {
    // setListOpcionUnica()
    console.log(`addAdi => element`, element);
    let listAux = [];
    element.forEach((e) => {
      listAux.push({
        id: e.idDetalleAdicional,
        nombre: e.nombre,
        precio: e.precio,
        cantidad: listCantidad[e.idDetalleAdicional] ?? 1,
      });
    });
    listOpcionAdi[id] = listAux;
    setListOpcionAdi({ ...listOpcionAdi });
  };

  const addCantidad = (element, id, idCab) => {
    // setListOpcionUnica()
    listCantidad[id] = element;
    const indexDetalleAdi = (listOpcionAdi[idCab] ?? []).findIndex(
      (e) => e.id == id
    );
    if (indexDetalleAdi != -1) {
      listOpcionAdi[idCab][indexDetalleAdi] = {
        ...listOpcionAdi[idCab][indexDetalleAdi],
        cantidad: element,
      };
    }
    setListCantidad({ ...listCantidad });
  };

  const addListFinal = () => {
    const c = Object.values(listOpcionAdi);
    let f = 0;
    c.forEach((element) => {
      element.forEach((e) => {
        console.log(e, "iteracion");
        f = f + e.precio * e.cantidad;
      });
    });
    console.log(f, "precio adicional");
    const precioF = dataProducto.precio * cantidad;
    const a = {
      nombre: dataProducto.nombre,
      unitPrice: dataProducto.precio,
      precio: precioF.toFixed(2),
      cantidad: cantidad,
      unico: Object.values(listOpcionUnica),
      multiple: Object.values(listOpcionMult),
      adicional: Object.values(listOpcionAdi),
      precioUnidad: dataProducto.precio,
      idTipoProducto: dataProducto.idTipoProducto,
      idProducto: dataProducto.idProducto,
    };
    // console.log(a,'nose');
    listFinal.push(a);
    // setListFinal({...listFinal.push(a)})
    console.log(listFinal, "final");

    setListOpcionUnica({});
    setListOpcionMult({});
    setListOpcionAdi({});
    setCantidad(1);
    // setListFinal({})
  };

  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="bottom"
      className="modal-select-product-detail"
      style={{
        width: "80%",
        height: "80%",
        marginLeft: "10%",
        borderRadius: "50px 50px 0 0",
        backgroundColor: "#F2F2F2",
      }}
    >
      <div style={{ width: "100%", padding: "30px", textAlign: "end" }}>
        <Button
          onClick={() => setIsOpen(false)}
          style={{ borderRadius: "20px" }}
          icon={<CloseOutlined style={{ fontSize: "30px" }} />}
        ></Button>
      </div>
      <div
        style={{
          textAlign: "center",
          padding: "0",
          marginTop: "-100px",
          alignItems: "center",
        }}
      >
        <img
          src={getUrlImage(dataProducto.urlImagen)}
          style={{ width: "40%", height: "30%" }}
          alt={`Imagen de '${dataProducto.nombre}'`}
        />
        <h2 style={{ fontSize: "45px", fontWeight: "600" }}>
          {dataProducto.nombre}
        </h2>
        <h2 style={{ fontSize: "35px", padding: " 0 10% 0 10%" }}>
          {dataProducto.descripcion}
        </h2>
        <h2 style={{ fontSize: "50px", fontWeight: "600", color: "#A50B70" }}>
          {precio
            ? "S/ " + parseFloat(precio).toFixed(2)
            : parseFloat(dataProducto.precio).toFixed(2)}
        </h2>
        <Row>
          <Col span={11} style={{ textAlign: "end" }}>
            <Button
              onClick={() => restar()}
              style={{ width: "60px", height: "60px" }}
              icon={<MinusOutlined style={{ fontSize: "35px" }} />}
            ></Button>
          </Col>
          <Col span={2} style={{ textAlign: "center" }}>
            <h2 style={{ fontSize: "60px" }}>{cantidad}</h2>
          </Col>
          <Col span={11} style={{ textAlign: "initial" }}>
            <Button
              onClick={() => sumar()}
              style={{
                backgroundColor: "#A50B70",
                color: "white",
                width: "60px",
                height: "60px",
              }}
              icon={<PlusOutlined style={{ fontSize: "35px" }} />}
            ></Button>
          </Col>
        </Row>
        {mostrarPersonalizar ? null : (
          <div>
            {parseInt(dataProducto.idTipoProducto) === 2 ? (
              <Row className="row tipoProducto" style={{ paddingTop: "50px" }}>
                <Col
                  span={24}
                  style={{ textAlign: "center", padding: "0 0 0 25px" }}
                >
                  <Button
                    onClick={() => agregar()}
                    style={{
                      width: "40%",
                      height: "100px",
                      fontSize: "40px",
                      fontWeight: "500",
                      borderRadius: "15px",
                      backgroundColor: "#A50B70",
                      color: "white",
                    }}
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            ) : (
              // setMostrarPersonalizar(true)
              <Row style={{ paddingTop: "50px" }}>
                <Col
                  span={12}
                  style={{ textAlign: "end", padding: "0 25px 0 0" }}
                >
                  <Button
                    onClick={() => personalizar()}
                    style={{
                      width: "60%",
                      height: "100px",
                      fontSize: "40px",
                      fontWeight: "500",
                      borderRadius: "15px",
                    }}
                  >
                    Personalizar
                  </Button>
                </Col>
                <Col
                  span={12}
                  style={{ textAlign: "initial", padding: "0 0 0 25px" }}
                >
                  <Button
                    onClick={() => agregar()}
                    style={{
                      width: "60%",
                      height: "100px",
                      fontSize: "40px",
                      fontWeight: "500",
                      borderRadius: "15px",
                      backgroundColor: "#A50B70",
                      color: "white",
                    }}
                  >
                    Agregar
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        )}

        {mostrarPersonalizar ? (
          <div style={{ width: "90%", margin: "0 auto", paddingTop: "20px" }}>
            <Tabs defaultActiveKey="1" type="card">
              {detalleProducto
                ? detalleProducto.map((element, index) => {
                    return (
                      <TabPane
                        tabKey={`detalleProducto-${index}`}
                        tab={
                          <div style={{ margin: "0" }}>
                            <img
                              style={{ width: "120px", height: "100px" }}
                              src={getUrlImage(element.imagen)}
                              alt="Tab"
                            />
                          </div>
                        }
                        key={element.idCabOpcion}
                      >
                        <div
                          style={{
                            backgroundColor: "white",
                            marginTop: "-20px",
                            paddingTop: "20px",
                          }}
                        >
                          <h1
                            style={{
                              fontSize: "30px",
                              fontWeight: "500",
                              paddingTop: "1px",
                            }}
                          >
                            {element.titulo}
                          </h1>

                          <ScrollContainer
                            style={{
                              height: "23vh",
                              width: "100%",
                              padding: "2px",
                            }}
                          >
                            {parseInt(element.idTipoSeleccion) === 1 ? (
                              <Radio.Group
                                onChange={(e) =>
                                  addUnico(
                                    e,
                                    element.idCabOpcion,
                                    element.nombreCorto
                                  )
                                }
                                style={{ width: "100%" }}
                              >
                                <Row>
                                  {element.opciones.map((e, index) => {
                                    return (
                                      <Col
                                        key={index}
                                        span={24}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Row
                                          style={{
                                            margin: "0 auto",
                                            width: "80%",
                                          }}
                                        >
                                          <Col
                                            span={20}
                                            style={{ textAlign: "initial" }}
                                          >
                                            <img
                                              style={{
                                                height: "3vh",
                                                width: "7vh",
                                                margin: "15px",
                                                paddingRight: "50px",
                                                float: "left",
                                              }}
                                              src={getUrlImage(e.imagen)}
                                              alt="opciones productos"
                                            />
                                            <h1
                                              style={{
                                                padding: "25px 0 0 0",
                                                fontSize: "35px",
                                              }}
                                            >
                                              {e.descripcion}
                                            </h1>
                                          </Col>
                                          <Col
                                            span={4}
                                            style={{
                                              textAlign: "end",
                                              padding: "25px 0 0 0",
                                            }}
                                          >
                                            <Radio
                                              value={e.descripcion}
                                            ></Radio>
                                          </Col>
                                        </Row>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Radio.Group>
                            ) : element.idTipoSeleccion == 2 ? (
                              <Checkbox.Group
                                value={selectMul}
                                onChange={(e) =>
                                  addMultiple(
                                    e,
                                    element.idCabOpcion,
                                    element.nombreCorto,
                                    element.cantSelect
                                  )
                                }
                                style={{ fontSize: "30px" }}
                              >
                                <Row>
                                  {
                                    // element.opciones.length >= 6 ?
                                    //     element.opciones.map((elem, index) => {

                                    //         return (
                                    //             // <h1>{elem.descripcion}</h1>

                                    //             <Col span={12} style={{ textAlign: 'center' }}>
                                    //                 <Row style={{ margin: '0 auto', width: '80%' }}>
                                    //                     <Col span={18} style={{ textAlign: 'initial' }} >
                                    //                         <img style={{ height: '3vh', width: '7vh', margin: '15px', paddingRight: '50px', float: 'left' }} src={elem.imagen} /><h1 style={{ float: 'left', padding: '25px 0 0 0', fontSize: '35px' }}>{elem.descripcion}</h1>
                                    //                     </Col>
                                    //                     <Col span={2} style={{ textAlign: 'center', padding: '25px 0 0 0' }} >
                                    //                         <Checkbox value={elem.descripcion}></Checkbox>
                                    //                     </Col>
                                    //                 </Row>
                                    //             </Col>
                                    //         )
                                    //     })
                                    //     :
                                    element.opciones.map((elem, index) => {
                                      return (
                                        // <h1>{elem.descripcion}</h1>
                                        <Col
                                          key={`descripcion-${index}`}
                                          span={24}
                                          style={{ textAlign: "center" }}
                                        >
                                          <Row
                                            style={{
                                              margin: "0 auto",
                                              width: "80%",
                                            }}
                                          >
                                            <Col
                                              span={18}
                                              style={{ textAlign: "initial" }}
                                            >
                                              <img
                                                style={{
                                                  height: "3vh",
                                                  width: "7vh",
                                                  margin: "15px",
                                                  paddingRight: "50px",
                                                  float: "left",
                                                }}
                                                src={getUrlImage(elem.imagen)}
                                                alt="Copia de aqui el check"
                                              />
                                              <div
                                                style={{
                                                  float: "left",
                                                  paddingTop: "25px",
                                                  width: "250px",
                                                }}
                                              >
                                                <h1
                                                  style={{
                                                    padding: "5px 0 0 0",
                                                    fontSize: "35px",
                                                  }}
                                                >
                                                  {elem.descripcion}
                                                </h1>
                                              </div>
                                            </Col>
                                            <Col
                                              span={6}
                                              style={{
                                                textAlign: "end",
                                                padding: "25px 0 0 0",
                                              }}
                                            >
                                              <Checkbox
                                                value={elem.descripcion}
                                              ></Checkbox>
                                            </Col>
                                          </Row>
                                        </Col>
                                      );
                                    })
                                  }
                                </Row>
                              </Checkbox.Group>
                            ) : (
                              <Checkbox.Group
                                onChange={(e) => addAdi(e, element.idCabOpcion)}
                                style={{ fontSize: "30px" }}
                              >
                                <Row>
                                  {element.opciones.map((elem, index) => {
                                    console.log("tipoagregados", elem);
                                    return (
                                      <Col
                                        key={`tipoagregados-${index}`}
                                        span={24}
                                        style={{ textAlign: "center" }}
                                      >
                                        <Row
                                          style={{
                                            margin: "0 auto",
                                            width: "95%",
                                          }}
                                        >
                                          <Col
                                            span={2}
                                            style={{
                                              textAlign: "center",
                                              padding: "25px 0 0 0",
                                            }}
                                          >
                                            <Checkbox value={elem}></Checkbox>
                                          </Col>

                                          <Col
                                            span={11}
                                            style={{ textAlign: "initial" }}
                                          >
                                            <img
                                              style={{
                                                width: "80px",
                                                height: "80px",
                                                margin: "15px",
                                                float: "left",
                                              }}
                                              src={getUrlImage(elem.urlImagen)}
                                              alt={elem.nombre}
                                            />
                                            <div
                                              style={{
                                                float: "left",
                                                paddingTop: "25px",
                                                width: "150px",
                                              }}
                                            >
                                              <h1 style={{ fontSize: "30px" }}>
                                                {elem.nombre}{" "}
                                              </h1>
                                            </div>
                                          </Col>
                                          <Col span={4}>
                                            <h2
                                              style={{
                                                paddingTop: "35px",
                                                fontSize: "30px",
                                              }}
                                            >
                                              S/ {elem.precio}
                                            </h2>
                                          </Col>
                                          <Col span={7}>
                                            {listOpcionAdi[
                                              element.idCabOpcion
                                            ] ? (
                                              listOpcionAdi[
                                                element.idCabOpcion
                                              ]?.findIndex(
                                                (e) =>
                                                  e.id ==
                                                  elem.idDetalleAdicional
                                              ) != -1 ? (
                                                <ComponentAdicional
                                                  onChange={(e) =>
                                                    addCantidad(
                                                      e,
                                                      elem.idDetalleAdicional,
                                                      element.idCabOpcion
                                                    )
                                                  }
                                                />
                                              ) : null
                                            ) : null}
                                          </Col>
                                          <Col></Col>
                                        </Row>
                                      </Col>
                                    );
                                  })}
                                </Row>
                              </Checkbox.Group>
                            )}
                          </ScrollContainer>
                        </div>
                      </TabPane>
                    );
                  })
                : null}
            </Tabs>

            <Button
              onClick={() => agregar()}
              style={{
                backgroundColor: "#A50B70",
                fontSize: "45px",
                color: "white",
                height: "100px",
                width: "40%",
                marginTop: "30px",
              }}
            >
              Aplicar
            </Button>
          </div>
        ) : null}
      </div>
    </Drawer>
  );
}

export default ModalProducto;
