import { CloseOutlined } from "@ant-design/icons";
import Drawer from "react-modern-drawer";
import { Button, Image, Input, Space } from "antd";
import React, { useState } from "react";
import ComponenPrint from "../principal/ComponenPrint";

function DrawerPrint({
  openPrint,
  setOpenPrint,
  pFinal,
  setPagar,
  setMostrarComprobante,
  listFinal,
}) {

  const toggleDrawer = () => {
    setOpenPrint(false);
  };

  return (
    <Drawer
      open={openPrint}
      onClose={toggleDrawer}
      direction="bottom"
      // className='bla bla bla'
      style={{
        width: "90%",
        height: "60%",
        marginLeft: "5%",
        borderRadius: "50px 50px 0 0",
      }}
    >
      <div style={{ width: "100%", padding: "30px", textAlign: "end" }}>
        <Button
          onClick={() => setOpenPrint(false)}
          style={{ borderRadius: "20px" }}
          icon={<CloseOutlined style={{ fontSize: "30px" }} />}
        ></Button>
      </div>
      <h1 style={{ fontSize: "40px", textAlign: "center" }}>Mi Boleta</h1>

      <div style={{ marginLeft: "30%" }}>
        <ComponenPrint listFinal={listFinal} pFinal={pFinal} />
      </div>
    </Drawer>
  );
}

export default DrawerPrint;
